<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Cookies</h1>
      <h2>Cookies on Derivitec</h2>
    </panel>
    <panel class="columns">
      <h3 class="third">Cookies</h3>
      <div class="two-thirds">
        <p>Cookies are files saved on your phone, tablet or computer when you visit a website.</p>
        <p>We use cookies to store information about how you use the Derivitec website, such as the
          pages you visit.</p>
        <h4>Cookie settings</h4>
        <p>We use 2 types of cookie. You can choose which cookies you're happy for us to use.</p>
        <h5>Cookies that measure website use</h5>
        <p>
          We use Google Analytics to measure how you use the website so we can improve it based on
          user needs. We do not allow Google to use or share the data about how you use this site.
        </p>
        <p>
          Google Analytics sets cookies that store anonymised information about:
          <ul>
            <li>how you got to the site</li>
            <li>the pages you visit on <a href="/">derivitec.com</a>, and how long you spend on each
              page</li>
            <li>what you click on while you're visiting the site</li>
          </ul>
        </p>
        <fieldset class="cookie-toggle">
          <label>
            <input type="radio" :value="true" v-model="preference" />
            Use cookies that measure my website use
          </label>
          <label>
            <input type="radio" :value="false" v-model="preference" />
            Do not use cookies that measure my website use
          </label>
        </fieldset>
        <h5>Strictly necessary cookies</h5>
        <p>These essential cookies do things like remebering your preferences set on this page while
          using the site.</p>
        <p>They always need to be on.</p>
      </div>
    </panel>
  </div>
</template>
<script lang="ts">
import {
  Component, Vue, Watch,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import { prefAsObject, enable, disable } from '../scripts/cookieControl';

@Component({
  name: 'CookieSettings',
  components: { Panel },
})
export default class CookieSettings extends Vue {

  preference: boolean = false;

  enable() {
    enable();
  }

  disable() {
    disable();
  }


  created(){
    if(prefAsObject().measure){
      this.preference = true
    }

    const bc = new BroadcastChannel("CookieChannel")

    bc.onmessage = (event) =>{
      if(event.data === "CookiePreferenceUpdate"){
        this.preference = prefAsObject().measure
      }
    }

  }

  @Watch('preference')
  onPreference(newValue: boolean) {
    if (newValue) {
      enable('measure');
    } else {
      disable('measure');
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../styles/utilities';

  .head {
    @include coverImage('../assets/imgs/backgrounds/recess-of-building-from-ground-level.jpg');
  }

  ul {
    list-style: circle;
    margin-left: 1em;
  }

  h4 {
    margin-top: $verticalMargin;
    margin-bottom: $verticalMargin--small;
  }

  h5 {
    margin-bottom: $verticalMargin--smallest;
  }

  .cookie-toggle {
    margin-bottom: $verticalMargin;
    label {
      display: block;
      margin: 1.5em;
    }
    input[type='radio'] {
      -webkit-appearance: none;
      width: 25px;
      height: 25px;
      margin-right: 1.5em;
      border-radius: 50%;
      border: 3px solid $blue;
      background: transparent;
      outline: none;
      transition: 0.25s transform ease-in-out, 0.15s background-color ease-in-out;
      &:after {
          content: '\2713';
          color: $white;
          font-size: 1.8em;
          width: 18px;
          line-height: 20px;
          text-align: center;
          display: block;
        }
      &:checked {
        background: $blue;
      }
      &:focus, &:hover, &:active {
        transform: scale(1.2);
      }
    }
  }
</style>
