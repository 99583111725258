<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Hedge Fund Platforms & Prime Brokers</h1>
      <h2>Calculate. Integrate. Evaluate.</h2>
    </panel>
    <panel>
      <div class="columns">
        <h4 class="two-thirds">From initial analysis<br />to final attribution.</h4>
      </div>
      <div class="columns right">
        <p class="static">Derivitec products have been designed to allow users
          to understand the risk on their portfolios clearly, and effectively. Available
          out of the box as a powerful web application, or integrated directly into the
          client&rsquo;s own applications, Derivitec covers your risk needs from initial
          analysis to final attribution, providing complete transparency across all stakeholders
          in your organisation.</p>
      </div>
    </panel>
    <panel class="calculate">
      <h3>Features & Benefits</h3>
      <h4>Calculate</h4>
      <div class="columns">
        <div class="half">
          <p>Hosted natively in the cloud, Derivitec risk engines will automatically scale out to
            handle the complexity of your requirements. With industry standard models and a 24-7
            market data process running in the background, Derivitec can calculate arbitrarily
            complex reports across all major asset classes.</p>
        </div>
        <div class="half">
          <h5>Find Out More</h5>
          <collapsible heading="Market Data">
            <p>Derivitec runs a 24-7 market data process, gathering raw data from multiple sources,
              including Thomson Reuters and Xignite, and then converting that data into model ready
              &lsquo;derived data&rsquo; objects (yield curves, vol surfaces etc). With coverage
              across equities, foreign exchange, interest rates, commodities, credit and fixed
              income, Derivitec supplies end of day data at no extra cost, allowing you to calculate
              the risk on your portfolio, no matter how complex the asset class make-up.</p>
          </collapsible>
          <collapsible heading="Models">
            <p>With an aggregate of decades of experience building pricing and risk models for tier
              one investment banks, Derivitec are recognised experts in derivatives modeling. All of
              our models are mark-to-market, where possible, and rigorously tested with the same
              attention to detail as a leading investment bank or hedge fund.</p>
          </collapsible>
          <collapsible heading="Compute">
            <p>From day one, Derivitec built to scale. Hosted in Amazon Web Services powerful
              elastic compute architecture, Derivitec resources scale to meet demand, as rapidly as
              demand ramps up. We can thereby calculate the many risks you may want to look at, no
              matter how complex and large the calculation may be.</p>
          </collapsible>
          <collapsible heading="Reporting">
            <p>Derivitec believes that effective risk management is all about having as many views
              as you need to make informed decisions. In addition to a powerful suite of standard
              reports covering end of day risk, collateral risk, profit and loss attribution, and
              value-at-risk, Derivitec builds reports to fit your needs, together with any
              treatment bespoke to your organisation.</p>
          </collapsible>
        </div>
      </div>
    </panel>
    <panel>
      <h3>Features & Benefits</h3>
      <h4>Integrate</h4>
      <div class="columns">
        <div class="half">
          <p>We supply APIs to support every part of your process, from the initial order to final
            PnL attribution, enabling you to leverage the power of our data and analytics on top of
            your existing workflow systems with minimal integration cost.</p>
        </div>
        <div class="half">
          <h5>Find Out More</h5>
          <collapsible heading="Order Management">
            <p>Our APIs can plug directly into the code of your preferred order management system,
              allowing for real time updates of your positions, exactly reconciled to your books
              and records.</p>
          </collapsible>
          <collapsible heading="Live Data">
            <p>Through a process of market data overrides against our end of day derived data,
              Derivitec can integrate with your third-party live data feeds, enabling real time
              calculation of your risk, viewable through in house applications and the Derivitec
              Risk Portal itself.</p>
          </collapsible>
          <collapsible heading="Portfolio Management">
            <p>Derivitec can both source data and supply data through our API. We can integrate
              our cloud-based pricing architecture directly into your preferred portfolio
              management system, allowing you to combine our risk capabilities in a single
              workflow application.</p>
          </collapsible>
        </div>
      </div>
    </panel>
    <panel class="evaluate">
      <h3>Features & Benefits</h3>
      <h4>Evaluate</h4>
      <div class="columns">
        <div class="half">
          <p>Available out of the box through our award-winning Risk Portal, or through Python
            integration, you can drill into your risk numbers, both pre and post trade, in as
            much detail as you require. Our APIs also allow you to prepare white labelled risk
            reports for your own stakeholders. Complete transparency, exactly the
            way you want it.</p>
        </div>
        <div class="half">
          <h5>Find Out More</h5>
          <collapsible heading="Pre-trade">
            <p>With our Python APIs, you can source whatever information you need to build
              informed, pre-trade analysis. How much would this product cost to hedge if the
              market evolved under current conditions? What would be the effect on my risk limits
              if I put this on? Can I hedge this product better? Our APIs allow you to combine the
              limitless flexibility of the Python data framework with exactly the data and numbers
              you need to make these, and any other key pre-trade decisions</p>
          </collapsible>
          <collapsible heading="White labelling">
            <p>The Derivitec Risk Portal is a great place to run risk reports, but wouldn&rsquo;t
              you also like something which has exactly the look and feel of your company? Derivitec
              is happy to work with you to combine our own API functionality with proprietary data
              and your own preferred data representations to create something which adds value for
              both you and your stakeholders.</p>
          </collapsible>
        </div>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import Collapsible from '@/components/Collapsible.vue';

@Component({
  name: 'Product',
  components: {
    Panel,
    Collapsible,
  },
})
export default class Product extends Vue {

}
</script>
<style lang="scss" scoped>
  @import "../styles/utilities";

  .head {
    @include coverImage('../assets/imgs/backgrounds/stock-price-data-on-screen.jpg');
  }
  .calculate, .evaluate {
    background-color: $grey--lighter;
  }
</style>
