import {useRoute} from "vue-router/dist/vue-router";

const GAID = 'G-ZBFQ5GH2G9';

const addTag = () => {
  const tag = document.createElement('script');
  tag.async = 1;
  tag.src = `https://www.googletagmanager.com/gtag/js?id=${GAID}`;
  document.body.appendChild(tag);
};

export default class GA {
    added = false;

    active = false;

    evtHandler = null;

    constructor() {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line prefer-rest-params
      function gtag() { window.dataLayer.push(arguments); }

      gtag('js', new Date());
      gtag('config', GAID, { anonymize_ip: true });
      this.gtag = gtag;
    }

    enable() {

      if(!window.location.host.toLowerCase().replace("www.", "").startsWith("derivitec")) return

      if (!this.added) addTag();
      window[`ga-disable-${GAID}`] = false;
      this.evtHandler = ({ detail: { title, path } }) => this.gtag('config', GAID, {
        page_title: title,
        page_path: path,
      });
      window.addEventListener('vueRouteChanged', this.evtHandler, false);
      this.added = true;
      this.active = true;
    }

    disable() {
      window[`ga-disable-${GAID}`] = true;
      if (typeof this.evtHandler === 'function') {
        window.removeEventListener('vueRouteChanged', this.evtHandler);
        this.evtHandler = null;
      }
      this.active = false;
    }
}
