<template>
  <span class="name-tag">
    <p class="name">{{name}}</p>
    <p v-if="subline">{{subline}}</p>
  </span>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';

@Component({
  name: 'NameTag',
})
export default class NameTag extends Vue {
  @Prop()
  name: string

  @Prop()
  subline: string
}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .name-tag {
    @include name-tag('.name', 'p');
  }
</style>
