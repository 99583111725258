<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/mike-armitage.jpg" alt="Photo of Mike Armitage"/>
    </template>
    <div>
      <h2>Mike Armitage</h2>
      <h3>CTO</h3>
      <p>Michael comes to us with over 10 years of experience in various asset management roles
        within futures settlements, trading and private wealth management. As IT Director for
        Stanhope Capital, Michael was fundamental in the creation of an entire business workflow
        solution. As Chief Architect to BRT, a business process outsourcing and bespoke software
        solution provider for wealth managers, funds, private banks and trusts, he created a white
        label SAAS version of the solution which was then made available to the rest of BRT's
        clients.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'MikeArmitageProfile',
  components: {
    Profile,
  },
})
export default class MikeArmitageProfile extends Vue {

}
</script>
