<template>
  <div>
    <panel class="head bg-right-sm" :bg="true">
      <h1>Hedge Fund Platforms & Prime Brokers</h1>
      <h2>See the whole picture,<br/>across all your clients.</h2>
    </panel>
    <panel class="columns no-gutter-md lopsided-right">
      <h4 class="third">One single, powerful platform.</h4>
      <p class="static">The Derivitec Risk Portal allows you to share reports within your
        organisation, but also outside your organisation. You can bring all your hedge fund clients
        onto a single platform, with a consistent set of models and data, allowing them to
        understand their risks clearly, and you to see the complete picture across the whole
        portfolio. From regulatory reporting to value-at-risk, bring everyone onto a single,
        powerful, platform, and keep ahead of the never-ending demands on transparency and risk
        management.</p>
    </panel>
    <panel class="features">
      <div class="columns">
        <div class="third">
          <h3 class="right">Features & Benefits</h3>
        </div>
        <div class="static divider">
          <h4>Shareability</h4>
          <p>You run a community of funds. Isn&rsquo;t it time they all had access to best-in-class
            technology? The Risk Portal allows you to on-board new clients seamlessly, and ensure
            that their risk, and your risk, is computed in exactly the same way. No more disputes.
            No more vaguery. Clear, well managed risk, exactly the way you want it.</p>
        </div>
      </div>
      <div class="columns flexibility">
        <div class="static divider">
          <h4>Flexibility</h4>
          <p>No two brokerages compute margin the same way. No two brokerages look at risk the
            same way. Derivitec will work with you to implement the margin rules of you and your
            liquidity providers, exactly, in our system, and will update accordingly as things
            change. Our Python technology will allow you to construct whatever report you like,
            and display it exactly as you and your clients need to see it, and when you&rsquo;re
            done, we’ll schedule things to ensure that the reports are run without you having to do
            anything at all.</p>
        </div>
      </div>
      <div class="columns right">
        <div class="static divider">
          <h4>Detail</h4>
          <p>You don&rsquo;t just need to know where your risks are, you need to know why, and what
            you can do to manage them. Derivitec takes all the information you’re prepared to give
            us, down to who traded what, for who, when, so you can drill down to the last detail
            when things look problematic, before they become a problem.</p>
        </div>
      </div>
    </panel>
    <panel class="columns">
      <div class="third">
        <client-box :standalone="true" :no-border="true">
          <img src="../assets/imgs/inserts/mirabella-logo.png"/>
        </client-box>
      </div>
      <div class="two-thirds">
        <blockquote>
          <p>It is vital that our process uses tools of the highest standard.</p>
          <p>Since we are the market leading regulatory platform it is vital that our process
            uses tools of the highest standard to be able to calculate and report accurate data
            held by our managers as required by law. Derivitec has helped a lot in that aspect
            by providing a clear and easy-to-use platform that we can use to generate
            our daily SSR report.</p>
          <footer>
            <cite>Joe Vittoria</cite>
            <p>CEO | Mirabella</p>
          </footer>
        </blockquote>
        <router-link class="button" to="/clients">Read More</router-link>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import ClientBox from '@/components/ClientBox.vue';

@Component({
  name: 'Platforms',
  components: {
    ClientBox,
    Panel,
  },
})
export default class Platforms extends Vue {

}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .head {
    @include coverImage('../assets/imgs/backgrounds/person-facing-towards-window.jpg');
  }

  .features {
    background-color: $grey--lighter;
  }

  .flexibility {
    margin-bottom: 665px;

    &:after {
      content: '\00a0';
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 210px;
      height: 665px;
      right: 0;
      bottom: calc(50% - 665px - 10vw);
      @include retinaImage('../assets/imgs/inserts/laptop-displaying-fx-marketdata.png', 324);
    }
  }

  @include x-small {
    .flexibility {
      margin-bottom: 565px;

      &:after {
        width: calc(210px + 15vw);
        height: calc(665px + 15vw);
        bottom: calc(50% - 665px + 11vw);
      }
    }
  }

  @include small {
    .flexibility {
      @media (max-width: $bp_medium - 1) {
        width: $magicHalfWidth;
      }
      margin-bottom: 0;

      &:after {
        content: '\00a0';
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 210px;
        height: 665px;
        right: 0;
        bottom: calc(50% - 400px);
      }
    }
  }

  @include medium {
    .flexibility:after {
      width: 324px;
      height: 742px;
    }
  }
</style>
