<template>
  <div class="panel" :class="{'with-image': bg}">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';

@Component({
  name: 'Panel',
})
export default class Panel extends Vue {
  @Prop({type: Boolean, default:false})
  bg: Boolean
}
</script>

<style lang="scss">
  @import "../styles/utilities";
  @import "../styles/grid";

  .panel {
    position: relative;
    background-color: white;

    // Header panel is full length
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      background-color: $black;

      // Chevron "down" indicator
      &:after {
        position: absolute;
        // Mobiles don't include their screen space covered by their chrome in their vh calc
        // Javascript can see a true value, if the browser can use css variables, position the arrow
        // with this value instead
        top: calc(100vh - 15px - 65px);
        top: calc((var(--vh, 1vh) * 100) - 15px - 65px);
        left: calc(50% - 19px);
        width: 38px;
        height: 15px;
        content: '\00a0';
        background-image: url('../assets/imgs/chevron.png');
        background-size: cover;
        transition: 0.25s top ease-in-out;
      }

      // Usually an h1 and an h2 in a head panel, some positioning
      h1 {
        margin-bottom: 40px;
        @include small {
          margin-bottom: 96px;
        }
        @include medium {
          margin-bottom: 56px;
          margin-top: 130px;
        }
        @include large {
          margin-bottom: 96px;
          margin-top: 0;
        }
      }
      h1 + h2 {
        margin-bottom: 3.5vh;
        @include small {
          margin-bottom: 0;
        }
        @include large {
          margin-bottom: 8.5vh;
        }
      }
    }

    // Anything with an image needs background sizing and rules to fix in place
    &.with-image {
      position:relative;
      background-attachment: fixed;
      background-size: cover;
      background-position:center top;
      @media (max-width: $bp_small - 1) {
        &.bg-right-sm, &.bg-right-md {
          // Standard centre alignment hides the man below 500px
          background-position-x: 144vw;
        }
      }
      @include small {
        @media (max-width: $bp_medium - 1) {
          &.bg-right-md {
            // Standard centre alignment hides the man below 500px
            background-position-x: right;
          }
        }
      }
      @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
      }
    }
    padding: $panelPadding_vertical $panelPadding_horizontal;
    @include small {
      padding: $panelPadding_vertical--small $panelPadding_horizontal--small;
    }
    @include medium {
      padding: $panelPadding_vertical--medium $panelPadding_horizontal--medium;
    }
    @include large {
      padding: $panelPadding_vertical--large $panelPadding_horizontal--large;
    }
  }

  h1 {
    .with-image & {
      color: lightslategrey;
    }
  }

  h2, h4, p, blockquote {
    .with-image & {
      color: white;
    }
  }
</style>
