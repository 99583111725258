<template>
  <testimonial>
    <h2>Multi-strategy Hedge&nbsp;Fund, Hong&nbsp;Kong</h2>
    <q>Amongst the largest pan-Asian independent alternative investment management groups with US$30
      billion in AUM across private equity, real estate, and absolute returns.</q>
    <h3>The Story</h3>
    <q>Derivitec was chosen as the risk engine providing VaR, shock and exposure numbers across the
      entire publicly traded portfolio. Our services were used both on a stand-alone basis, via the
      Risk Portal, but also as an integrated pricing and position management service, within the
      firm’s OMS and PMS.</q>
    <h3>Full Integration With Your Workflow</h3>
    <p>The Derivitec risk system allows for automatic integration with your position
      management platform, through API connectivity, or secure FTP. The system can also be set up to
      run all reports of interest to your company, in whatever form you want them, on a scheduled
      basis, leaving you with nothing more to do than absorb those reports into your internal
      reporting and analysis.</p>
    <q>Derivitec was chosen for modelling as well as to be the pricing and risk engine providing
      VaR, stress test shock scenarios and exposure numbers across the Public Markets book. Risk
      calcs and reports were scheduled to run daily for analysis in the Risk Portal. Derivitec also
      provided ad hoc analytics via tailor-made python scripts using their python API.</q>
    <h3>One-to-One Service</h3>
    <p>Derivitec prides itself on dedicated, one-to-one support with all our clients.
      Whether you need new functionality, new reports, or just advice on pricing and risk
      management, we’re here for you.</p>
    <q>For the past two and a half years Derivitec has proven to be the consummate team for
      quantitative modelling, pricing and risking of our multi-strategy public markets Book in its
      entirety. Additionally, Derivitec’s robust IT infrastructure and user-friendly Risk Portal are
      unparalleled inasmuch as they afford us a complete suite of analytical and reporting tools
      that allow for in-depth analysis and fine toothcomb scrutiny of portfolio structures at fund
      and entity levels. Also praiseworthy is Derivitec’s peerless data management and enrichment
      process of multi-decade financial time series across all asset classes.</q>
  </testimonial>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Testimonial from '@/components/Testimonial.vue';

@Component({
  name: 'MSHFTestimonial',
  components: {
    Testimonial,
  },
})
export default class MSHFTestimonial extends Vue {

}
</script>

<style lang="scss" scoped>
h2 {
  width: 60%;
}
</style>
