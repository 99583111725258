import { createStore } from 'vuex';
const news = require('@/assets/news.json');

export default createStore({
  state: {
    news: [],
    error: false
  },
  getters: {
    newsLookup: (state) => state.news
      .reduce(
        (acc, item: any) => Object
          .assign(
            acc,
            { [item.id]: item },
          ),
        {},
      ),
  },
  mutations: {
    setNews(state, news) {
      state.news = news
    },
    setErrorView(state, display) {
     state.error = display
    },
  },
  actions: {
    async getNews(context) {
      context.commit('setNews', news);
    },
  },
});
