<template>
  <v-dialog-transition v-if="bannerItem" dark>
    <v-card class="cookie-card text-shades-white" :color="bannerItem.bannerColour" tile elevation="5" :to="`/news/${bannerItem.id}`">
      <template v-slot:title>
       <div class="d-flex flex-row justify-space-between white--text"><v-toolbar-title :class="[{'text-caption':IsMobile},{'text-button':!IsMobile}]">{{bannerItem.subTitle}} - "{{bannerItem.title}}" - Click here for more details...</v-toolbar-title></div>
      </template>
    </v-card>
  </v-dialog-transition>
</template>

<script lang="ts">
import { Component,  Vue } from 'vue-facing-decorator';
import {useStore} from "vuex";
import { useDisplay } from 'vuetify'


@Component({
  name: 'TimedBanner',
})
export default class TimedBanner extends Vue {

  bannerItem: any  | null = null

  get store() {
    return useStore();
  }

  get news(){
    return this.store.state.news
  }

  async created() {

    let dateToday = Date.now();
    await this.store.dispatch('getNews');
    this.bannerItem = this.news.find(c => c.event && c.eventDate && Date.parse(c.eventDate) > dateToday)
  }

  get IsMobile(){
    const { mobile } = useDisplay()
    return mobile.value}

}
</script>

<style lang="scss" scoped>

.cookie-card{
  position: sticky;
  z-index: 2000;
  width:100%;
  bottom:0;
}
</style>
