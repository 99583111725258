<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Contact Us</h1>
      <h2>Learn more about Derivitec.</h2>
    </panel>
    <panel>
      <div class="columns">
        <div class="half">
          <p>To speak with our team about Derivitec products and services, simply provide
            us with your contact information and we will respond promptly.</p>
          <figure>
            <figcaption>Phone</figcaption>
            <a href="tel:+442036683682">+44 (203) 668 3682</a>
          </figure>
          <figure>
            <figcaption>Email</figcaption>
            <a href="mailto:info@derivitec.com">info@derivitec.com</a>
          </figure>
          <p>Thank you for your interest in Derivitec. We look forward to connecting with you.</p>
        </div>
        <div class="half">
          <p class="success" v-if="success === true">Thank you for your enquiry, we will respond in
            due course.</p>
          <p class="error" v-if="success === false">An error occurred sending your enquiry, please
            <a href="mailto:info@derivitec.com">email</a> or <a href="tel:+442036683682">phone</a>
            to make an enquiry.</p>
          <form @submit.prevent="send" v-if="success !== true">
            <fieldset :disabled="isSending || success === false">
              <text-field placeholder="First Name" v-model="first" class="half"></text-field>
              <text-field placeholder="Last Name" v-model="last" class="half"></text-field>
              <text-field placeholder="Company Name" v-model="company"></text-field>
              <text-field placeholder="Email" v-model="email"></text-field>
              <text-field placeholder="Phone" v-model="phone"></text-field>
              <dropdown title="Type of Firm" placeholder="Select firm type" v-model="firm"
                        :options="firms"></dropdown>
              <dropdown title="Country" placeholder="Select Country" v-model="country"
                        :options="countries"></dropdown>
              <text-area-field placeholder="Enquiry" v-model="enquiry"></text-area-field>
              <button class="button">{{buttonText}}</button>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="columns office">
        <div class="half">
          <h3>Head Office</h3>
          <address itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
            <p>
              <span itemprop="streetAddress">Level 39, 1 Canada Square</span>,
              <span itemprop="addressLocality">London</span>&nbsp;
              <span itemprop="postalCode">E14 5AB</span><br />
              Tel: <a href="tel:+442036683682" itemprop="telephone">+44 (0)203 668 3682</a>
            </p>
          </address>
        </div>
        <div class="half">
          <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=Level%2039%2C%201%20Canada%20Square%2C%20London%20%20E14%205AB&key=AIzaSyBOJ6zZLLCeUx8p94gzK6_pnCMGpPFAzro" allowfullscreen></iframe>
        </div>
      </div>
      <div class="columns office">
        <div class="half">
          <h3>US Office</h3>
          <address itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
            <p>
              <span itemprop="streetAddress">450 Lexington Ave New York</span>,
              <span itemprop="postalCode">NY 10017</span>
            </p>
          </address>
        </div>
        <div class="half">
          <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.3971657995457!2d-73.97800202397225!3d40.753288571387415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259026dac3895%3A0x2e2ca693614b3144!2s450%20Lexington%20Ave%2C%20New%20York%2C%20NY%2010017%2C%20USA!5e0!3m2!1sen!2suk!4v1710238921022!5m2!1sen!2suk" allowfullscreen></iframe>
        </div>
      </div>
      <div class="columns office">
        <div class="half">
          <h3>APAC Office</h3>
          <address itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
            <p>
              <span itemprop="streetAddress">12/F Mass Mutual Tower, 33 Lockhart Road</span>,
              <span itemprop="addressLocality">Hong Kong</span>
              <br />
              Tel: <a href="tel:+85239054192" itemprop="telephone">+852 3905 4192</a>
            </p>
          </address>
        </div>
        <div class="half">
          <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=10F%2C%20China%20Evergrande%20Centre%2C%2033%20Lockhart%20Rd%2C%20Wan%20Chai%2C%20Hong%20Kong&key=AIzaSyBOJ6zZLLCeUx8p94gzK6_pnCMGpPFAzro" allowfullscreen></iframe>
        </div>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import TextField from '@/components/TextField.vue';
import Dropdown from '@/components/Dropdown.vue';
import TextAreaField from '@/components/TextArea.vue';

const CountriesFile = require('@/assets/countries.json');

@Component({
  name: 'Contact',
  components: {
    Panel,
    TextField,
    Dropdown,
    TextAreaField,
  },
})
export default class Contact extends Vue {
  isSending: boolean = false

  success: any = null

  firms: Array<string> = [
    'Hedge Fund',
    'Remittance Company',
    'Hedge Fund Platform',
    'Financial Software Vendor',
  ]

  first: string = ''

  last: string = ''

  company: string = ''

  email: string = ''

  phone: string = ''

  firm: string = ''

  country: string = ''

  enquiry: string = ''

  get countries() {
    return CountriesFile.Countries?.reduce((acc, { top, name }) => {
      acc[Number(!top)].push(name);
      return acc;
    }, [[], []])
      .map((arr) => arr.sort());
  }

  get buttonText() {
    if (this.isSending) return 'Sending...';
    if (this.success === true) return 'Sent';
    if (this.success === false) return 'Error';
    return 'Submit';
  }

  async send() {
    const {
      first,
      last,
      company,
      email,
      phone,
      firm,
      country,
      enquiry,
    } = this;
    this.isSending = true;
    let body;
    try {
      ({ body } = await fetch(process.env.VUE_APP_CONTACT_URL, {
        method: 'POST',
        body: JSON.stringify({
          first,
          last,
          company,
          email,
          phone,
          firm,
          country,
          enquiry,
        }),
      })
        .then((r) => r.json()));
      this.success = body.indexOf('true') > -1;
    } catch {
      this.success = false;
    }
    this.isSending = false;
  }
}
</script>
<style lang="scss" scoped>
  @import "../styles/utilities";

  .head {
    @include coverImage('../assets/imgs/backgrounds/industrial-tunnel.jpg');
  }

  .half :first-child {
    margin-top: 0;
  }

  figure {
    font-family: $fontPlayfair;
    font-size: $fontsize--medium;

    & ~ figure {
      margin-top: $verticalMargin--larger;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    figcaption {
      @include small-title;
      margin-bottom: 35px;
    }
  }

  p {
    &.success {
      color: $green;
    }
    &.error {
      color: $red;
    }
  }

  .office {
    padding-top: $panelPadding_vertical;
    a {
      color: $grey;
      text-decoration: none;
      &:hover, &:focus, &:active {
        text-decoration: underline;
      }
    }
  }

</style>
