<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/george-kaye.jpg" alt="Photo of George Kaye" />
    </template>
    <div>
      <h2>George Kaye</h2>
      <h3>CEO</h3>
      <p>George Kaye started his career at Credit Suisse First Boston’s Product Development Group in
        1999, quickly specialising in equity derivatives. In 2006, he left to join the Derivative
        Analysis Group of Goldman Sachs, where he focused on building a methodology for model risk
        analysis of the firm's equity derivatives positions. In 2010 he returned to the front
        office, working in the equity derivatives section of the Quantitative Analysis Group of UBS,
        leaving at the end of 2011 to build his own derivatives software company.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'GeorgeKayeProfile',
  components: {
    Profile,
  },
})
export default class GeorgeKayeProfile extends Vue {

}
</script>
