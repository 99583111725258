<template>
  <div>
    <panel class="head bg-right-sm" :bg="true">
      <h1>{{itemTitle}}</h1>
      <h2>Clients</h2>
    </panel>
    <panel class="columns py-20" id="pageContent">
      <div class="third">
        <h3><router-link class="nav-link" :to="'/clients'">Clients</router-link> / {{itemTitle}}</h3>
      </div>
      <div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import {useRoute} from "vue-router";
@Component({
  name: 'Testimonial',
  components: { Panel },
})
export default class Testimonial extends Vue {
  route: any | null = null

  get itemTitle(){return this.route?.meta?.title ?? "Loading...."}

  mounted(){
    this.route = useRoute()
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .content {
    width: 95%;
    margin: 0 auto 100px;
    ::v-deep {
      > p {
        @include emphasised-text;
      }
      > q {
        @include body-text(true);
        margin: $verticalMargin--larger 0;
      }
    }
    @include small {
      width: 600px;
    }
    @include medium {
      width: 800px;
    }
  }

  .head {
    @include coverImage('../assets/imgs/backgrounds/people-walking.jpg');
  }
</style>
