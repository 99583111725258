import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3876692b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal",
  tabindex: "1",
  id: "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-target" }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: _ctx.closeLink,
        class: "close",
        tabindex: "1"
      }, {
        default: _withCtx(() => [
          _createTextVNode("×")
        ]),
        _: 1
      }, 8, ["to"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}