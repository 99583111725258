<template>
  <testimonial>
    <h2>Mirabella</h2>
    <q>The launch of a new investment management firm, or the extension of an existing venture, can
      take many forms, depending on the immediate requirements of the team involved. Meeting those
      needs can sometimes involve partnering with an organization that has deep expertise in
      regulatory hosting. Mirabella is the preferred institutional-quality solution for regulatory
      incubation because of the strong oversight and controls the team brings to each organization’s
      project. Mirabella enables firms to get up and running quickly by operating under its
      regulatory agency.</q>
    <h3>Detailed Regulatory Reporting</h3>
    <p>Derivitec’s Risk Portal deals with everything from pre-trade hedging analysis through
      to regulatory reporting, integrating the latest sets of rules into accurate regulator ready
      reports.</p>
    <q>Shareholding disclosure requires daily aggregation of all holdings held by our entities and
      reporting to strict deadlines. Given Mirabella’s diverse strategies with different delivery
      methods, the solution has to be flexible, scalable and with the minimum input possible. This
      is what Derivitec helps us deliver. In a nutshell, one file is uploaded into the system and
      two reports are generated. One aggregates according to the Short Selling Regulation and
      identifies positions where Mirabella holds -0.2% or below of issued shares. The other in line
      with the Transparency Directive and helps identify positions for which a major holding or a
      takeover panel notification has to be made. For the latter, specific metrics are derived given
      divergence of reporting requirements in different countries.</q>
    <h3>One-to-One Service</h3>
    <p>Derivitec prides itself on dedicated, one-to-one support with all our clients.
      Whether you need new functionality, new reports, or just advice on pricing and risk
      management, we’re here for you.</p>
    <q>Collaborating with Derivitec for shareholding disclosure reporting has been a great pleasure
      since day one. The response and efficiency have always been impeccable. Whether an issue with
      unmapped identifiers, missing market data or new functionalities such as linking to ESMA
      registers, deriving from first principles weighted voting rights because data in the market
      was deemed unsatisfactory, the team always delivered to high standards.</q>
    <NameTag name="Joe Vittoria" subline="CEO | Mirabella" />
  </testimonial>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Testimonial from '@/components/Testimonial.vue';
import NameTag from '@/components/NameTag.vue';

@Component({
  name: 'MirabellaTestimonial',
  components: {
    Testimonial,
    NameTag,
  },
})
export default class MirabellaTestimonial extends Vue {

}
</script>
