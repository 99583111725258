<template>
  <transition name="fade">
    <slot></slot>
  </transition>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component({
  name: 'FadeTransition',
})
export default class FadeTransition extends Vue {
}

</script>
<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
