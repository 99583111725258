<template>
  <figure class="headshot">
    <div class="img-wrap">
      <slot></slot>
    </div>
    <figcaption>
      <NameTag :name="name" :subline="job" />
    </figcaption>
  </figure>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';
import NameTag from './NameTag.vue';

@Component({
  name: 'Headshot',
  components: { NameTag },
})
export default class Headshot extends Vue {
  @Prop()
  name: string

  @Prop()
  job: string
}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .headshot ::v-deep img {
    width: 100%;
    display: block;
  }

  figcaption {
    margin-top: $verticalMargin--smaller;
  }

  a:focus .img-wrap {
    outline: 1px solid $blue;
  }

  @include card-hover('.img-wrap');
</style>
