import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f5a1666"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row justify-space-between white--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog_transition = _resolveComponent("v-dialog-transition")!

  return (_ctx.bannerItem)
    ? (_openBlock(), _createBlock(_component_v_dialog_transition, {
        key: 0,
        dark: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "cookie-card text-shades-white",
            color: _ctx.bannerItem.bannerColour,
            tile: "",
            elevation: "5",
            to: `/news/${_ctx.bannerItem.id}`
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_toolbar_title, {
                  class: _normalizeClass([{'text-caption':_ctx.IsMobile},{'text-button':!_ctx.IsMobile}])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.bannerItem.subTitle) + " - \"" + _toDisplayString(_ctx.bannerItem.title) + "\" - Click here for more details...", 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ])
            ]),
            _: 1
          }, 8, ["color", "to"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}