import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: "collapsible",
    tabindex: "0",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
    onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.key && _ctx.key(...args)))
  }, [
    _createElementVNode("header", null, [
      _createElementVNode("h6", {
        class: _normalizeClass({'open': _ctx.open})
      }, _toDisplayString(_ctx.heading), 3)
    ]),
    _createElementVNode("main", {
      class: _normalizeClass(["content", {'open': _ctx.open}])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 32))
}