<template>
    <div id="app">
        <site-header></site-header>
        <div class="appView overflow-auto">
          <router-view v-if="!showError"/>
          <error-display v-else/>
        </div>
        <site-footer></site-footer>
        <cookie-popup/>
        <timed-banner></timed-banner>
    </div>
</template>

<script lang="ts">
import {
  Component, Vue, toNative, Watch
} from 'vue-facing-decorator';
import SiteHeader from '@/components/Header.vue';
import ErrorDisplay from '@/views/Error.vue';
import SiteFooter from '@/components/Footer.vue';
import CookiePopup from '@/components/CookiePopup.vue';
import FadeTransition from "@/components/Fade.vue"
import {useStore} from "vuex";
import {RouteLocationNormalizedLoaded, Router, useRouter} from "vue-router";
import TimedBanner from "@/components/TimedBanner.vue";
const setHeight = (height) => document.documentElement.style.setProperty('--vh', `${height * 0.01}px`);
const get = (obj, path) => path
  .split('.')
  .reduce(
    (acc, part) => (acc && typeof acc === 'object' ? acc[part] : acc),
    obj,
  );
@Component({
  name: 'App',
  components: {
    TimedBanner,
    SiteHeader,
    ErrorDisplay,
    SiteFooter,
    CookiePopup,
    FadeTransition
  },
})
export default class App extends Vue {
  static mounted() {
    if (get(window, '__PRERENDER_INJECTED.prerendering')) return;
    let height = window.innerHeight;
    setHeight(height);
    window.addEventListener('resize', () => {
      const newHeight = window.innerHeight;
      if (height !== newHeight) {
        height = newHeight;
        setHeight(height);
      }
    });
  }

  store: any | null = null

  router: Router | null = null

  created(){
    this.store = useStore();
  }

  get showError(){return this.store?.state?.error}
}
</script>

<style lang="scss">

@import './styles/utilities';
@import './styles/reset';
@import './styles/text';

  noscript p {
    background: $white;
    position: fixed;
    z-index: 999;
    padding: 2vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    font-size: 2vh;
    border-top: 1px solid $black;
  }
.appView{
  min-height:100vh;
  background-color: $black;
}

</style>
