<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Awards</h1>
      <h2>Our Software Awards</h2>
    </panel>
    <panel>
      <h1>Awards</h1>
      <v-container>
        <v-row>
          <v-col class="pa-5">
            <v-timeline align="start" :side="isMobile ? 'end' : undefined">
              <v-timeline-item
                v-for="(year, i) in awardsList"
                :key="i"
                :dot-color="year.color"
                size="small"
              >
                <template v-slot:opposite>
                  <div
                    :class="`pt-1 headline font-weight-bold text-${year.color}`"
                    v-text="year.date"
                  ></div>
                </template>
                <div :class="[{'text-right': (i % 2) && !isMobile}]">
                  <h3 class="mb-4">{{year.subTitle}}</h3>
                  <h4 :class="`mt-n1 headline font-weight-light mb-4 text-${year.color}`" style="white-space: pre">
                    {{year.title}}
                  </h4>
                  <h3>
                    {{year.description}}
                  </h3>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>
    </panel>

  </div>
</template>s

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import Headshot from '@/components/Headshot.vue';
import {useDisplay} from "vuetify";

const rewardLogo = require("@/assets/imgs/news/derivitec-wins-hedgeweeks-best-risk-management-software-provider-2018.png")

@Component({
  name: 'Awards',
  components: {
    Panel,
    Headshot,
  },
})
export default class Awards extends Vue {

  get isMobile() {return useDisplay().mobile.value}

  get awardsList(): any[] {
    return [
      {
        color: "primary",
        imgUrl: rewardLogo,
        subTitle: "HedgeWeek",
        title: "Global Awards\nWinner",
        date: "2018",
        description: "Awarded Best Risk Management Software provider in the 2018 Hedgeweek Global Awards",
        link: "https://www.hedgeweek.com/derivitec-ltd-best-risk-management-software-provider/"
      },
      {
        color: "primary",
        title: "European Awards\nShortlisted",
        subTitle: "HedgeWeek",
        date: "2023",
        description: "Shortlisted for Best Risk Management Software provider in the 2023 Hedgeweek European Awards",
      },
      {
        color: "primary",
        title: "European Awards\nShortlisted",
        subTitle: "HedgeWeek",
        date: "2024",
        description: "Shortlisted for Best Risk Management Software provider in the 2024 Hedgeweek European Awards",
      }
    ]
  }

}
</script>

<style lang="scss" scoped>
@import '../styles/utilities';

.head {
  @include coverImage('@/assets/imgs/backgrounds/person-facing-towards-window.jpg');
}
</style>
