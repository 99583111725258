<template>
  <router-link class="nav-link logo-link" to="/">
    <img class="logo" src="../assets/imgs/logo.png" alt="Derivitec logo"/>
  </router-link>
</template>

<script lang="ts">
import { Component,  Vue } from 'vue-facing-decorator';

@Component({
  name: 'Logo',
})
export default class Logo extends Vue {

}
</script>

<style scoped>
  .logo {
    width: 176px;
  }
</style>
