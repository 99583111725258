<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Forex Remittance</h1>
      <h2>Because the risks of margining are not marginal.</h2>
    </panel>
    <panel class="portal columns right">
      <div class="two-thirds half-lg">
        <p>The Derivitec Risk Portal allow you to compute your margin requirements
          to your dealing banks, and the margin requirements from your clients, under
          whatever market scenario you choose. Imagine you had known, in detail, which
          client, on which book, managed by which dealer, on which product, was going
          to suffer on the 24th June 2016. Our clients did, and managed the crisis
          effectively, before it happened. Imagine each client, from individual to
          corporate, could see their collateral risk clearly and intuitively, every day,
          top to bottom. With our Python reporting technology integrating into your in
          house client portal, they can.</p>
        <strong>The days of ‘not knowing’ are over.</strong>
      </div>
    </panel>
    <panel class="features">
      <div class="columns transparency">
        <div class="static divider">
          <h3>Features & Benefits</h3>
          <h4>Transparency</h4>
          <p>All divisions of your organisation, be they trading, treasury, credit or
            operations, can see exactly where your risks are, on exactly the same basis.
            Every client can see precisely what they need to know, down to the last detail,
            in exactly the way you want them to see it.</p>
        </div>
      </div>
      <div class="columns right">
        <div class="static divider">
          <h4>Flexibility</h4>
          <p>No two remittance firms compute margin the same way. No two dealing banks
            likewise. Derivitec will work with you to implement the margin rules of you and
            your liquidity providers, exactly, in our system, and will update accordingly as
            things change.</p>
        </div>
      </div>
      <div class="columns detail">
        <div class="static divider">
          <h4>Detail</h4>
          <p>You don&rsquo;t just need to know where your risks are, you need to know why, and
            what you can do to manage them. Derivitec takes all the information you’re prepared
            to give us, down to who traded what, for who, when, so you can drill down to the
            last detail when things look problematic, before they become a problem.</p>
        </div>
      </div>
    </panel>
    <panel class="columns">
      <div class="third">
        <client-box :standalone="true" :no-border="true">
          <img class="company-logo" src="../assets/imgs/inserts/afex-logo.png" />
        </client-box>
      </div>
      <div class="two-thirds">
        <blockquote>
          <p>Derivitec has been instrumental in helping us manage counterparty collateral
            in the wake of the Brexit shock.</p>
          <p>Its real value was really prior to the event itself, when we were able to stress test
            portfolios and warn clients of impending collateral requirements as well as potential
            negative market movements so that clients would be prepared well in advance. This
            helped clients assess the cash flow impact to their business and decide how they
            wished to manage the uncertainty risk around the possible outcomes of the
            referendum.</p>
          <footer>
            <cite>Sheila Krishnan</cite>
            <p>Global Credit Manager | AFEX</p>
          </footer>
        </blockquote>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import ClientBox from '@/components/ClientBox.vue';

@Component({
  name: 'RemittanceFunds',
  components: {
    ClientBox,
    Panel,
  },
})
export default class RemittanceFunds extends Vue {

}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .head {
    @include coverImage('../assets/imgs/backgrounds/glass-building.jpg');
  }
  .features {
    background-color: $grey--lighter;
    overflow: hidden;
  }
  .portal, .transparency:after, .detail:after {
    background-repeat: no-repeat;
    background-size: 35vw;
    background-position: 0 0;
  }
  .transparency, .detail {
    &:after {
      position: absolute;
      content: '\00a0';
      background-size: contain;
    }
  }
  .portal {
    background-image: url('../assets/imgs/inserts/tablet-displaying-volatility-from-right.png');
    background-position: bottom right;
  }
  .detail:after {
    width: 235px;
    height: 290px;
    right: 0;
    bottom: -100px;
    background-image: url('../assets/imgs/inserts/browser-displaying-fx-volatility.png');
  }
  @include small {
    .portal {
      background-size: 40vw;
    }
    .transparency, .detail {
      // Adhere to original sizing outside of small and large bps
      @media (max-width: $bp_medium - 1) {
        width: $magicHalfWidth;
      }
    }
    .transparency:after {
      width: 300px;
      height: 520px;
      right: -30px;
      top: $panelPadding_vertical--small;
      background-image: url('../assets/imgs/inserts/person-using-tablet-triangle.png');
    }
    .detail:after {
      background-position: bottom right;
      width: 350px;
      height: 580px;
      bottom: 0;
      right: -15vw;
    }
  }
  @include medium {
    .portal {
      background-image: url('../assets/imgs/inserts/tablet-displaying-volatility-from-left.png');
      background-position-y: center;
      background-position-x: -10vw;
    }
    .transparency:after {
      width: 350px;
      height: 520px;
      right: 0;
      top: $panelPadding_vertical--medium;
    }
    .detail:after {
      width: 480px;
    }
  }
  @include large {
    .portal {
      background-position: center left;
    }
    .detail:after {
      right: 0;
    }
  }
</style>
