<template>
  <div class="form-group">
    <input  type="text" v-model="value" required/>
    <label class="control-label">{{placeholder}}</label>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,  Model
} from 'vue-facing-decorator';

@Component({
  name: 'TextField',
})
export default class TextField extends Vue {
  @Prop()
  placeholder: string

  @Model()
  value: string

  @Prop()
  name: string

}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";
  @include input-base;

  .form-group {
    input {
      height: 1.9rem;
      display: block;
      background: $grey--lighter;
      padding: 19px 0 19px 0.5rem;
      border: 0.125rem solid transparent;
      line-height: 1.9;
      &:focus {
        border-color: $grey--lighter;
        background: white;
      }
    }
  }
</style>
