<template>
  <div class="form-group">
    <select name="option-control" v-model="selected" required>
      <option value="" disabled>{{placeholder}}</option>
      <template v-if="isMultiGroup">
        <optgroup v-for="(group, groupKey) in options" :key="groupKey">
          <option v-for="(item, key) in group" :key="key" :value="item">{{item}}</option>
        </optgroup>
      </template>
      <template v-else>
        <option v-for="(item, key) in options" :key="key" :value="item">{{item}}</option>
      </template>
    </select>
    <label for="option-control" class="control-label">{{title}}</label>
    <i class="fas fa-chevron-down arrow"></i>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch, Model
} from 'vue-facing-decorator';

@Component({
  name: 'Dropdown',
  components: {  },
})
export default class Dropdown extends Vue {
  @Prop()
  options: Array<any>

  @Prop()
  placeholder: string

  @Prop()
  title: string

  @Prop()
  value: string

  @Prop()
  name: string

  @Model()
  selected: string = ''

  get isMultiGroup() {
    return Array.isArray(this.options) && Array.isArray(this.options[0]);
  }

  @Watch('selected')
  onSelected(value: string) {
    this.$emit('input', value);
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";
  @include input-base;

  .form-group {
    padding: 7px 0;
    background: $grey--lighter;
    border-radius: 0.2em;
    select {
      z-index: 1;
      height: 1.6rem;
      padding-left: 0.6rem;
      background: transparent;
      border: none;
      line-height: 1.6;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
      option, optgroup {
        color: black;
        background: white;
      }
      &:focus {
        &:valid {
          color: $blue;
        }
        & ~ .arrow {
          transform: scale(1.25, 1.25);
        }
      }
    }
    .arrow {
      position: absolute;
      z-index: 0;
      right: 1rem;
      top: 0.8rem;
      color: $grey;
      transition: 0.28s transform ease;
    }
  }
</style>
