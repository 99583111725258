import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a9e6074"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headshot" }
const _hoisted_2 = { class: "img-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NameTag = _resolveComponent("NameTag")!

  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("figcaption", null, [
      _createVNode(_component_NameTag, {
        name: _ctx.name,
        subline: _ctx.job
      }, null, 8, ["name", "subline"])
    ])
  ]))
}