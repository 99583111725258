<template>
  <div>
    <panel class="head" :bg="true">
      <h1>{{ Name }}</h1>
      <h2>{{ breadcrumb }}</h2>
    </panel>
    <panel class="columns py-20" id="pageContent">

      <div class="third">
        <h3><router-link class="nav-link" :to="'/company'">Company</router-link> / {{breadcrumb}}</h3>
        <div class="content">
            <slot></slot>
        </div>
      </div>

      <div class="two-thirds">
        <div class="headshot">
          <div class="wrapper">
            <slot name="headshot" />
          </div>
        </div>
      </div>

    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import {useRoute} from "vue-router";
@Component({
  name: 'Profile',
  components: { Panel },
})
export default class Profile extends Vue {
  route: any | null = null

  get breadcrumb(){return this.route?.meta?.breadcrumb}

  get Name(){return this.route?.meta?.name}

  mounted(){
    this.route = useRoute()
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";
  .head {
    @include coverImage('../assets/imgs/backgrounds/curved-glass-building-from-ground-level.jpg');
  }
  $padding: 280px;
  $padding--mobile: 80px;

  .profile {
    display: flex;
    flex-direction: column-reverse;
    .headshot ::v-deep img {
      width: 100%;
      object-fit: cover;
    }
    .content {
      padding: $padding--mobile 6.25% 0;
      ::v-deep {
        h3 {
          @include emphasised-text;
          text-transform: none;
        }
      }
    }
    @include small {
      flex-direction: row;
      & > div {
        width: 50%;
      }
      .headshot {

        overflow: hidden;

        .wrapper {
          width: 100%;
          height: 100%;
        }

        ::v-deep img {
          width: auto;
          display: block;
          position: absolute;
          left: -50vw;
          right: -50vw;
          margin: auto;
        }
      }
      .content {
        margin-left: 50vw;
        padding-top: $padding;
      }
    }
    @include medium {
      .headshot ::v-deep img {
        max-width: none;
        height: 100vh;
      }
      .content {
        padding: $padding 12.5% 0;
      }
    }
    @include large {
      .headshot ::v-deep img {
        height: auto;
        width: 50vw;
      }
    }
  }
</style>
