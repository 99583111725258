<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Home</h1>
      <h2>One platform.<br />Infinite possibilities.</h2>
    </panel>
    <panel class="columns">
      <div class="third">
        <h3 class="right">Welcome to Derivitec</h3>
      </div>
      <div class="two-thirds">
        <h4>Complete transparency across all stakeholders</h4>
        <p>Available out of the box as a powerful web application, or integrated directly
          into the client’s own applications, Derivitec covers your risk needs from initial
          analysis to final attribution, providing complete transparency across all stakeholders
          in your organisation.</p>
        <strong>Understand your risk. <em>Properly.</em></strong>
      </div>
    </panel>
    <panel class="hedge columns" :bg="true">
      <div class="half">
        <h3>What we can do for you</h3>
        <h4>Hedge Funds</h4>
        <p>Ensure complete consistency across your organisation, front to back, pre and post
          trade, real time and end of day. View your risk in whatever way best fits your portfolio.
          The only limit is your imagination.</p>
        <router-link class="button light" to="/hedge-funds">Learn More</router-link>
      </div>
    </panel>
    <panel class="remittance columns">
      <div class="third">
        <h3>What we can do for you</h3>
      </div>
      <div class="half">
        <h4>Remittance Companies</h4>
        <p>Understand the risk on your collateral, to your liquidity providers, from your clients.
          Make sure you know who&rsquo;s not going to be able to pay margin before it happens.</p>
        <router-link class="button" to="/remittance-funds">Learn More</router-link>
      </div>
    </panel>
    <panel class="platforms columns" :bg="true">
      <div class="half">
        <h3>What we can do for you</h3>
        <h4>Platforms</h4>
        <p>Service the risk reporting needs of your client funds through a single application. See
          the complete risk picture across all your companies.</p>
        <router-link class="button light" to="/platforms">Learn More</router-link>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';

@Component({
  name: 'Home',
  components: {
    Panel,
  },
})
export default class Home extends Vue {

}
</script>
<style lang="scss" scoped>
  @import '../styles/utilities';
  @import '../styles/text';

  .head {
    @include coverImage('../assets/imgs/backgrounds/skyscrapers-from-ground-level.jpg');
    h1 {
      @include sr-only;
    }
    @include medium {
      h2 {
        font-size: 70px;
        // Take up the rest of the space, accounting for the left positioning
        width: calc(100% - 10vw);
      }
    }
  }

  .panel.hedge {
    @include panelImage('../assets/imgs/backgrounds/person-using-tablet.jpg');
  }

  .remittance {
    @include retinaImage('../assets/imgs/inserts/skyscraper-triangle.png', 159);
    background-repeat: no-repeat;
    background-size: 159px;
    background-position-y: bottom;
    background-position-x: calc(100vw - 130px);
    @include small {
      @include retinaImage('../assets/imgs/inserts/skyscraper-triangle.png', 277);
      background-size: 277px;
      background-position-x: calc(100vw - 225px);
    }
  }

  .platforms {
    @include panelImage('../assets/imgs/backgrounds/person-using-phone.jpg');
  }
</style>
