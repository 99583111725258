<template>
  <div>
    <panel class="head bg-right-sm" :bg="true">
      <h1>Clients</h1>
      <h2>How Derivitec works for us.</h2>
    </panel>
    <panel>
      <h3>Clients</h3>
      <div class="columns">
        <p class="half">Derivitec works with a diverse set of companies, from hedge funds to
          foreign exchanage remittance companies. Read about how they use Derivitec,
          in their own words.</p>
      </div>
      <ul class="grid">
        <li><router-link to="/clients/afex">
          <client-box company="AFEX">
            <img src="../assets/imgs/inserts/afex-logo.png" alt="AFEX logo" />
          </client-box>
        </router-link></li>
        <li><router-link :to="{ name: 'Mirabella' }">
          <client-box company="Mirabella">
            <img src="../assets/imgs/inserts/mirabella-logo.png" alt="Mirabella logo" />
          </client-box>
        </router-link></li>
        <li><router-link :to="{ name: 'mshf' }">
          <client-box>
            <h5 class="name">Multi-strategy Hedge&nbsp;Fund</h5>
            <p class="subline">Hong Kong</p>
          </client-box>
        </router-link></li>
      </ul>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import ClientBox from '@/components/ClientBox.vue';

@Component({
  name: 'Clients',
  components: {
    Panel,
    ClientBox,
  },
})
export default class Clients extends Vue {

}
</script>

<style lang="scss" scoped>
  @import '../styles/utilities';

  .head {
    @include coverImage('../assets/imgs/backgrounds/people-walking.jpg');
  }
  a {
    text-decoration: none;
  }
</style>
