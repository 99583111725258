<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/adam-karp.jpg" alt="Photo of Adam Karp"/>
    </template>
    <div>
      <h2>Adam Karp</h2>
      <p>Adam Karp was a Director at Bank of America Merrill Lynch within the Global Markets
        Equities division. As a member of the Prime Brokerage Sales and Coverage team, he was
        responsible for origination and relationship management across the EMEA region. Prior to
        joining Bank of America Merrill Lynch in 2016, Adam spent the previous 10 years at Credit
        Suisse, where he held numerous positions within the Prime Services business. Beginning in
        New York in 2006, as a hedge fund consultant, he was key in developing the operational and
        technology platform. In 2008 he relocated from New York to join the Prime Services
        Asia-Pacific team where he established the Prime Consulting, business consulting team
        regionally. In 2015 Adam relocated from Hong Kong to London, joining the Prime Services
        Coverage team. Prior to Credit Suisse, Adam worked at Eze Castle, a boutique technology
        consulting firm that focuses on the alternative investment management community.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'AdamKarpProfile',
  components: {
    Profile,
  },
})
export default class AdamKarpProfile extends Vue {

}
</script>
