<template>
  <div>
    <panel class="head" :bg="true">
      <h1>{{NewsTitle}}</h1>
      <h2>News</h2>
    </panel>
    <panel class="columns py-20" id="pageContent">
      <h3 class="third">
        <router-link class="nav-link" :to="'/news'">News</router-link> / {{NewsTitle}}</h3>
      <div class="two-thirds">
          <slot></slot>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Vue,
} from 'vue-facing-decorator';
import Modal from './Modal.vue';
import Panel from "@/components/Panel.vue";
import {useRoute} from "vue-router";

@Component({
  name: 'NewsItem',
  components: {Panel, Modal },
})
export default class NewsItem extends Vue {

  @Prop()
  NewsTitle: string

}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";
  .head {
    @include coverImage('../assets/imgs/backgrounds/angled-glass-facades-upwards.jpg');
  }
  .content {
    width: 95%;
    margin: 0 auto 200px;
    background-color: white;
    @include small {
      width: 600px;
    }
    @include medium {
      width: 900px;
    }
  }
</style>
