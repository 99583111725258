<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/marc-tuckmantel.jpg" alt="Photo of Marc Tuckmantel"/>
    </template>
    <div>
      <h2>Marc Tuckmantel</h2>
      <h3>Head of Product Development</h3>
      <p>Marc joined us from Goldman Sachs, where he worked on the equity derivatives desk in
        London. Marc was responsible for extending tools for risk management of flow products,
        implemented pricing models and developed proprietary algorithmic indices based on trading
        strategies for clients of the equity exotics desk. Before he joined Goldman Sachs, Marc
        worked as a doctoral researcher in CERN, conducting research into elementary particle
        physics and superstring theory.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'MarcTuckmantelProfile',
  components: {
    Profile,
  },
})
export default class MarcTuckmantelProfile extends Vue {

}
</script>
