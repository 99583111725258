import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "appView overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_site_header = _resolveComponent("site-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_error_display = _resolveComponent("error-display")!
  const _component_site_footer = _resolveComponent("site-footer")!
  const _component_cookie_popup = _resolveComponent("cookie-popup")!
  const _component_timed_banner = _resolveComponent("timed-banner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_site_header),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.showError)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : (_openBlock(), _createBlock(_component_error_display, { key: 1 }))
    ]),
    _createVNode(_component_site_footer),
    _createVNode(_component_cookie_popup),
    _createVNode(_component_timed_banner)
  ]))
}