<template>
  <div>
    <panel class="head" :bg="true">
      <h1>News</h1>
      <h2>Read the latest about Derivitec.</h2>
    </panel>
    <panel class="columns py-20">
      <h3 class="third">News</h3>
      <ul class="two-thirds">
        <li v-for="item in news" :key="item.id">
          <router-link :to="{ name: 'NewsItem', params: { id: item.id } }">
            <div class="article">
              <!-- image -->
              <h6 v-if="item.subTitle">{{ item.subTitle }}</h6>
              <h2 style="white-space:pre-wrap">{{ item.title }}</h2>
              <h5 v-if="item.date">{{ toDate(item.date) }}</h5>
              <p v-html="item.description"></p>
            </div>
          </router-link>
        </li>
      </ul>
    </panel>
  </div>
</template>

<script lang="ts">
import { Component,  Vue } from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import { format, parseISO } from 'date-fns';
import { useStore } from 'vuex';

@Component({
  name: 'NewsSummary',
  components: {
    Panel,
  },
})
export default class NewsSummary extends Vue {
  get store() {
    return useStore();
  }

  get news(){
    return this.store.state.news
  }

  async created() {
      await this.store.dispatch('getNews');
  }

  toDate(date) {
    return format(parseISO(date), 'MMMM yyyy');
  }
}
</script>
<style lang="scss" scoped>
  @import '../styles/utilities';

  .head {
    @include coverImage('../assets/imgs/backgrounds/angled-glass-facades-upwards.jpg');
  }

  a {
    text-decoration: none;
    color: $blue;
    transition: 0.25s color ease-in-out;
    &:hover, &:focus {
      color: $blue--dark;
    }
  }

  h4 {
    font-size: $fontsize--large;
    margin-bottom: $verticalMargin--smallest;
    line-height: 1.2em;
  }

  .article {
    margin: $verticalMargin--large 0;
    li:first-child & {
      margin-top: 0;
    }
    li:last-child & {
      margin-bottom: 0;
    }
    &:after {
      width: 33%;
      height: 1px;
      background-color: $grey--light;
      content: '';
      display: block;
    }
  }
</style>
