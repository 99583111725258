<template>
  <div class="client-box" :class="{'standalone': standalone, 'no-border': noBorder}">
    <h5 class="company-name" v-if="company">{{company}}</h5>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';

@Component({
  name: 'ClientBox',
})
export default class ClientBox extends Vue {
  @Prop()
  company: string

  @Prop()
  standalone: boolean

  @Prop()
  noBorder: boolean
}
</script>

<style lang="scss">
  @import "../styles/utilities";

  .client-box {
    padding: 30px 20px;
    background: white;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    &:not(.no-border) {
      border: 1px solid $grey--light;
    }
    &.standalone {
      width: 100%;
      min-height: 140px;
      margin-bottom: $verticalMargin--small;
      @include x-small {
        max-width: $bp_xs * 0.75;
      }
    }
    * {
      margin: 0;
      padding: 0;
    }
    .company-name {
      @include sr-only;
    }
    .name {
      vertical-align: middle;
      font-family: $fontPlayfair;
      text-transform: none;
      color: $black;
    }
    .subline {
      margin-top: 1.1em;
      vertical-align: middle;
      font-family: $fontSource;
      color: $blue;
      font-weight: bold;
      text-transform: uppercase;
    }
    img {
      width: 100%;
      margin: 2.5vw 0;
      // IE specific, explicitly state image height otherwise it stretches
      @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
        height: 100%;
      }
    }

    .name {
      font-size: $fontsize--resphuge;
    }
    .subline {
      font-size: $fontsize--resplarger;
    }
    &.standalone {
      .name {
        font-size: 125%;
      }
      .subline {
        font-size: 60%;
      }
    }
    @include x-small-horizontal {
      min-height: 20vw;
      .name {
        font-size: $fontsize--resplarger;
      }

      .subline {
        font-size: $fontsize--resplarge;
      }
    }
    @include small {
      min-height: 16vw;
      .name {
        font-size: $fontsize--respmedium;
      }
      .subline {
        font-size: $fontsize--respsmall;
      }
    }
    @include medium {
      min-height: 13vw;
    }
  }
  @include card-hover('.client-box');
</style>
