<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/derek-crane.jpg" alt="Photo of Derek Crane"/>
    </template>
    <div>
      <h2>Derek Crane</h2>
      <h3>COO | PAG Consulting</h3>
      <p>Mr.Crane is a Partner and Group Chief Operating Officer of PAG, one of Asia’s largest
        independent alternative investment management firms. Mr Crane has extensive experience in
        financial services and fund management. Prior to joining PAG in 2007, Mr.Crane held
        positions with ICAP Plc as CFO for Asia based in Hong Kong, and with UBS AG in Jersey
        Channel Islands as a Director responsible for fund structuring and administration. Mr.Crane
        is a FCMA, FCSI & FHKSI.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'DerekCraneProfile',
  components: {
    Profile,
  },
})
export default class DerekCraneProfile extends Vue {

}
</script>
