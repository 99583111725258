<template>
  <section class="collapsible" tabindex="0" @click="click" @keydown="key">
    <header>
      <h6 :class="{'open': open}">{{heading}}</h6>
    </header>
    <main class="content" :class="{'open': open}">
      <slot></slot>
    </main>
  </section>
</template>
<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';

@Component({
  name: 'Collapsible',
})
export default class Collapsible extends Vue {
  @Prop()
  heading: string

  open: boolean = false

  toggle() {
    this.open = !this.open;
  }

  click() {
    this.$el.blur();
    this.toggle();
  }

  key(e) {
    const keys = [
      'Enter',
      'Space',
    ];
    const isKey = (key) => e.code === key;
    if (keys.some(isKey)) {
      e.preventDefault();
      this.toggle();
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../styles/utilities";

  $collapsibleWidth--medium: calc(300 / #{$bp_medium--num} * 100vw);
  $collapsibleWidth--large: calc(300 / #{$bp_large--num} * 100vw);

  .collapsible {
    min-height: 115px;
    overflow: hidden;
    margin-bottom: 30px;
    outline: none;
    cursor: pointer;
    position: relative;
    &:focus h6 {
      text-decoration: underline;
    }
    p {
      font-family: $fontSource;
      font-size: 18px;
      color: $grey;
      letter-spacing: 0.05em;
      line-height: 1.45em;
      font-weight: 300;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 50px;
      background-color: $blue;
      @include medium {
        width: $collapsibleWidth--medium;
      }
      @include large {
        width: $collapsibleWidth--large;
      }
    }
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: 0.5s max-height ease;
    &.open {
      max-height: 1000vh;
      @media (min-height: 800px) {
        max-height: 150vh;
      }
      @include small {
        max-height: 55vh;
      }
      @include medium {
        max-height: 60vh;
      }
    }
  }

  h6 {
    position: relative;
    font-family: $fontPlayfair;
    color: $blue;
    font-size: 24px;
    width: 100%;
    margin-bottom: 40px;
    @include medium {
      width: $collapsibleWidth--medium;
    }
    @include large {
      width: $collapsibleWidth--large;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      font-weight: bold;
      width: 25px;
      height: 25px;
      text-align: center;
      transition: 0.5s transform ease;
      @include x-small {
        content: '+';
      }
    }
    &.open:after {
      transform: rotate(45deg);
    }
  }
</style>
