<template>
  <news-item :NewsTitle="newsItem?.title">
    <div v-if="newsItem">
      <h6 v-if="newsItem.subTitle">{{newsItem.subTitle}}</h6>
      <h2 style="white-space:pre-wrap">{{ newsItem.title }}</h2>
      <h3 v-if="newsItem.date">{{ newsItem.author }}, {{ humanDate }}</h3>
      <img v-if="image" class="featured-image" :src="image" />
      <div class="pa-5" v-html="newsItem.body"></div>
    </div>
    <p v-else>The news article does not exist.</p>
  </news-item>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import NewsItem from '@/components/NewsItem.vue';
import { parseISO, format } from 'date-fns';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

@Component({
  name: 'NewsSingle',
  components: { NewsItem },
})
export default class NewsSingle extends Vue {
  async created() {
    if (typeof this.newsItem === 'undefined') {
      const store = useStore();
      await store.dispatch('getNews');
    }
  }

  get newsItem() {
    const store = useStore();
    const route = useRoute();
    return store.getters.newsLookup[route?.params?.id.toString()];
  }

  get humanDate() {
    if (!this.newsItem?.date || !('date' in this.newsItem)) return false;
    return format(parseISO(this.newsItem.date), 'MMMM yyyy');
  }
  get image() {
    return this.newsItem.image ? require(`../assets/imgs/news/${this.newsItem.id}.png`) : null;
  }
}
</script>
<style lang="scss" scoped>
  @import '../styles/utilities';

  h2 {
    margin-bottom: $verticalMargin--smallest;
  }

  ::v-deep {
    h4 {
      @include emphasised-text;
    }

    ul, ol {
      padding-left: $verticalMargin--small;
      li {
        @include body-text();
        margin: $verticalMargin--smallest 0;
      }
    }

    ul li:before {
      content: "\25CB";
      margin-left: -1.2em;
      padding-right: 10px;
      font-weight: bold;
      font-size: 1.2em;
    }

    ol li {
      list-style: decimal;
    }

    strong {
      @include body-text;
      font-family: $fontSource;
      font-weight: bold;
    }
  }

  .featured-image {
    float: left;
    margin: 10px 15px;
    max-width: 40%;
  }
</style>
