import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Fade from './components/Fade.vue';
import FontAwesomeIcon from "@/components/Icon.vue";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

// Create the Vue app instance
const app = createApp(App);

// eslint-disable-next-line
app.component('fade', Fade);

app.component('font-awesome-icon', FontAwesomeIcon);

// Mount the app instance to the DOM
const mountedApp = app
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');

document.addEventListener('DOMContentLoaded', function () {
  setTimeout(function () {
    document.dispatchEvent(new Event('vue-post-render'))
  }, 1)
})
