<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/kevin-meehan.jpg" alt="Photo of Kevin Meehan"/>
    </template>
    <div>
      <h2>Kevin Meehan</h2>
      <p>Kevin is a Non-Executive Director, Senior Business Leader and Alternative Asset Specialist,
        guiding the delivery of global, commercial business strategies for the Financial Services
        sector. His executive career includes several years with Credit Suisse as Managing Director
        of Prime Brokerage in Hong Kong and then Managing Director / Global Head of Prime Fund
        Services (PFS) in London, turning around PFS and directing a large-scale change programme to
        return it to profitability. He has now built a NED/Advisory portfolio leveraging 25+ years’
        experience across all verticals of the hedge fund sector including asset management, fund
        admin, legal and prime brokerage to drive decision making.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'KevinMeehanProfile',
  components: {
    Profile,
  },
})
export default class KevinMeehanProfile extends Vue {

}
</script>
