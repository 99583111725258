<template>
  <v-dialog-transition>
    <v-card v-if="show" title="Cookie Preference" class="cookie-card" color="primary" tile elevation="5">
      <template v-slot:text>
        <div class="d-flex flex-row w-100">
          <div class="flex-grow-1">
            We serve cookies on this site to analyze traffic, remember your preferences, and optimize your experience.
          </div>

          <v-btn
            variant="outlined"
            class="mx-3"
            @click="navToCookies()"
          >
            Disagree
          </v-btn>
          <v-btn
            variant="outlined"
            @click="enableCookies()"
          >
            Agree
          </v-btn>
        </div>
      </template>
    </v-card>
  </v-dialog-transition>
</template>


<script lang="ts">
import router from '../router';
import { cookieOptions, enable, prefAsObject} from '../scripts/cookieControl';
import {Component, Vue} from "vue-facing-decorator";

@Component({
  name: 'CookiePopUp',
  components: { },
})
export default class CookieSettings extends Vue {

  show: boolean = false;


  get Accepted(){ return localStorage.getItem("cookie:accepted") === "true"}
  set Accepted(value:boolean){ localStorage.setItem("cookie:accepted", `${value}`)}

  mounted(){
    setTimeout(() => {
      if(!this.Accepted){
      this.show = true
     }
    }, 1000)
  }


  get preferences() { return prefAsObject()}

  enableCookies(){
    enable('all');
    this.Accepted = true
    this.show = false
  }

  navToCookies(){
    this.Accepted = false
    router.push({ name: 'CookieSettings' })
    this.show = false
  }


  get cookieOptions(){return cookieOptions}


}

</script>

<style lang="scss" scoped>

  .cookie-card{
    position: sticky;
    z-index: 2000;
    width:100%;
    bottom:0;
    height: 25vh;
    opacity: 0.8;
  }

</style>
