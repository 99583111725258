<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/jon-hodges.jpg" alt="Photo of Jon Hodges"/>
    </template>
    <div>
      <h2>Jon Hodges</h2>
      <h3>CEO | Alphakinetic</h3>
      <p>Jon has worked in financial software development for over 10 years. He initially worked for
        a consultancy within JP Morgan building large-scale high volume auto-trading systems
        specialising in FIX protocol and order book management. Later he moved onto the buy-side
        working with Credit Derivatives (CDO's, CDS) and Fixed Income (Bonds, Repos, ABS, MBS). He
        has been heavily involved in building valuation frameworks for structured products, working
        closely with the quantitative analysts. Jon also worked in Beijing to establish and manage
        offshore development teams.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'JonHodgesProfile',
  components: {
    Profile,
  },
})
export default class JonHodgesProfile extends Vue {

}
</script>
