
import GA from './ga';

const gaInst = new GA();

const storageKey = 'derivitec-cookie-preferences';

const MEASURE = 'measure';
const NECESSARY = 'necessary';

export const cookieOptions = {
  path: '/',
  domain: 'derivitec.com',
  expires: '1Y',
  samesite: 'strict',
  secure: true,
};

export const preferences = new Map([
  [MEASURE, false],
  [NECESSARY, true],
]);

const react = () => {
  if (preferences.get(MEASURE)) {
    gaInst.enable();
  } else {
    gaInst.disable();
  }

  const bc = new BroadcastChannel("CookieChannel")
  bc?.postMessage("CookiePreferenceUpdate")

};

let stored;
try {
  stored = JSON.parse(window.localStorage.getItem(storageKey));
} catch {
  stored = JSON.parse(getCookie(storageKey));
}

if (typeof stored === 'object' && stored !== null) {
  Object.keys(stored).forEach((key) => preferences.set(key, stored[key]));
  react();
}

export const prefAsObject = () => Array.from(preferences)
  .reduce((acc, [key, val]) => Object.assign(acc, { [key]: val }), {});

const serialise = () => JSON.stringify(prefAsObject());

const persist = () => {
    window.localStorage.setItem(storageKey, serialise());
};

export const enable = (type = 'all') => {
  if (type === 'all') {
    preferences.forEach((val, key) => preferences.set(key, true));
  }
  if (preferences.has(type)) preferences.set(type, true);
  persist();
  react();
};

export const disable = (type = 'all') => {
  if (type === 'all') {
    preferences.forEach((val, key) => {
      if (key !== 'necessary') {
        preferences.set(key, false);
      }
    });
  }
  if (preferences.has(type) && type !== 'necessary') preferences.set(type, false);
  persist();
  react();
};
