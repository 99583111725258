import {createRouter, createWebHistory, RouterOptions} from 'vue-router';

import Store from '@/store';
import Home from '@/views/Home.vue';
import HedgeFunds from '@/views/HedgeFunds.vue';
import RemittanceFunds from '@/views/RemittanceFunds.vue';
import Platforms from '@/views/Platforms.vue';
import Product from '@/views/Product.vue';
import Services from '@/views/Services.vue';
import Clients from '@/views/Clients.vue';
import Afex from '@/views/Testimonials/Afex.vue';
import Mirabella from '@/views/Testimonials/Mirabella.vue';
import MSHF from '@/views/Testimonials/MSHF.vue';
// import Partners from '@/views/Partners.vue';
// import Enfusion from '@/views/Partners/Enfusion.vue';
import Company from '@/views/Company.vue';
import Contact from '@/views/Contact.vue';
import NewsSummary from '@/views/NewsSummary.vue';
import NewsSingle from '@/views/NewsSingle.vue';
import CookieSettings from '@/views/CookieSettings.vue';
import Awards from "@/views/Awards.vue"
import GeorgeKaye from "@/views/Profiles/GeorgeKaye.vue";
import MarcTuckmantel from "@/views/Profiles/MarcTuckmantel.vue";
import MikeArmitage from "@/views/Profiles/MikeArmitage.vue";
import DerekCrane from "@/views/Profiles/DerekCrane.vue";
import DavidShannon from "@/views/Profiles/DavidShannon.vue";
import JonHodges from "@/views/Profiles/JonHodges.vue";
import AdamKarp from "@/views/Profiles/AdamKarp.vue";
import KevinMeehan from "@/views/Profiles/KevinMeehan.vue";
import {
Vue,
} from 'vue-facing-decorator';
const splitPascal = /([a-z])([A-Z])/g;

class employee{
  name: string;
  role: string | null;
  team: string;
  component: any
}
const profileComponents = {
  GeorgeKaye,
  MarcTuckmantel,
  MikeArmitage,
  DerekCrane,
  DavidShannon,
  JonHodges,
  AdamKarp,
  KevinMeehan,
};

export const company: employee[] = [
  {team: "Management Team", name: "George Kaye", role:"CEO", component: GeorgeKaye},
  {team: "Management Team", name: "Marc Tuckmantel", role:"Head of Product Development", component: MarcTuckmantel},
  {team: "Management Team", name: "Mike Armitage", role:"CTO", component: MikeArmitage},
  {team: "Company Directors", name: "Derek Crane", role:"'COO | PAG Consulting", component: DerekCrane},
  {team: "Company Directors",name: "David Shannon", role:"Partner | Assembly Capital Partners", component: DavidShannon},
  {team: "Advisory Board", name: "Jon Hodges", role:"'CEO | Alphakinetic'", component: JonHodges},
  {team: "Advisory Board", name: "Adam Karp", role: null, component: AdamKarp},
  {team: "Advisory Board", name: "Kevin Meehan", role: null, component: KevinMeehan}
]


const profileRoutes = company.map((employee) => ({
  path: "/company/" + employee.name.replaceAll(" ","").replace(splitPascal, '$1-$2').toLowerCase(),
  name: employee.name.replaceAll(" ",""),
  component: employee.component,
  meta: { noScroll: true, scrollToContent:true, breadcrumb: employee.team, name: employee.name}
}));

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    if (from.path.includes(to.path) || to.meta["noScroll"]){

      if(to.meta["scrollToContent"]){
        document?.querySelector('#pageContent')?.scrollIntoView({
            behavior: 'smooth',
          });
        return new Promise(resolve => setTimeout(resolve, 500));
      }

      return false
    };
    const pos = savedPosition || { left: 0, top: 0 };
    const { left, top } = pos;
    let y = top;
    if (to.params.scrollToContent) y = window.innerHeight;
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: y,
        left,
        behavior: 'smooth',
      });
      return new Promise(resolve => setTimeout(resolve, 500));
    }
    return { left, top };
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/hedge-funds',
      name: 'HedgeFunds',
      component: HedgeFunds,
    },
    {
      path: '/remittance-funds',
      name: 'RemittanceFunds',
      component: RemittanceFunds,
    },
    {
      path: '/platforms',
      name: 'Platforms',
      component: Platforms,
    },
    {
      path: '/product',
      name: 'Product',
      component: Product,
    },
    {
      path: '/awards',
      name: 'Awards',
      component: Awards,
    },
    {
      path: '/services',
      name: 'Services',
      component: Services,
    },
    {
      path: '/clients',
      name: 'Clients',
      component: Clients,
    },
    {
      path: '/clients/afex',
      name: 'Afex',
      component: Afex,
      meta: { noScroll: true, title: 'Afex', scrollToContent: true },
    },
    {
      path: '/clients/mirabella',
      name: 'Mirabella',
      component: Mirabella,
      meta: { noScroll: true, title: 'Mirabella', scrollToContent: true  },
    },
    {
      path: '/clients/multi-strategy-hedge-fund',
      name: 'mshf',
      component: MSHF,
      meta: { noScroll: true, title: 'Multi-Strategy Hedge Fund', scrollToContent: true  },
    },
    /* {
      path: '/partners',
      name: 'Partners',
      component: Partners,
      children: [
        {
          path: 'enfusion',
          name: 'Enfusion',
          component: Enfusion,
          meta: { noScroll: true },
        },
      ],
    }, */
    {
      path: '/company',
      name: 'Company',
      component: Company,
    },
    ...profileRoutes,
    {
      path: '/news',
      name: 'News',
      component: NewsSummary,
    },
    {
      path: '/news/:id',
      name: 'NewsItem',
      component: NewsSingle,
      meta: {
        noScroll: true,
        scrollToContent:true,
        title: ({ params }) => (params.id in Store.getters.newsLookup
          ? Store.getters.newsLookup[params.id].title : 'News'),
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/cookies',
      name: 'CookieSettings',
      component: CookieSettings,
      meta: {
        title: 'Cookies on Derivitec',
      },
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    Store.commit('setErrorView', true);
  } else if (Store.state.error) {
    Store.commit('setErrorView', false);
  }
  next();
});

const suffix = ' | Derivitec';
router.afterEach((to) => {
  let title;
  if (to.matched.length === 0) return;
  if (typeof to.meta === 'object') {
    ({ title } = to.meta);
  }
  switch (typeof title) {
    case 'function':
        title = title(to);
      break;
    case 'undefined':
      title = to?.name?.toString().replace(splitPascal, '$1 $2');
      break;
    default:
    // The title needs no further processing
  }
  document.title = title + suffix;
});

router.afterEach((to, from) => {
  const { path, name } = to;
  const event = new CustomEvent('vueRouteChanged', {
    detail: {
      title: document.title,
      name,
      path,
    },
  });
  window.dispatchEvent(event);
});

export default router;
