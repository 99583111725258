<template>
    <Teleport to="#modal-target">
      <div class="modal" tabindex="1" id="modal">
        <router-link :to="closeLink" class="close" tabindex="1">&times;</router-link>
        <slot></slot>
      </div>
    </Teleport>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-facing-decorator';
import { useRoute } from 'vue-router';
@Component({
  name: 'Modal'
})
export default class Modal extends Vue {
  @Prop({ type: String, default: '' })
  closeTo: string

  get closeLink() {
    const route = useRoute();
    return this.closeTo.length > 0 ? this.closeTo : route.matched[0].path;
  }

  created() {
    document.body.style.overflow = 'hidden';
  }

  unmounted() {
    document.body.style.overflow = 'visible';
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/utilities';

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: white;
    overflow-y: auto;
    &.fade-leave-active {
      overflow-y: hidden;
    }
  }

  .close {
    position: absolute;
    right: 4vw;
    top: 4vh;
    text-decoration: none;
    font-size: 50px;
    color: $blue;
    &:hover {
      color: lighten($blue, 10%);
    }
  }
</style>
