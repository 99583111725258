import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-442f477f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pb-3 pt-4 elevation-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_menu_toggle = _resolveComponent("menu-toggle")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_logo),
    _createVNode(_component_menu_toggle, {
      active: _ctx.isMobile && _ctx.showMenu,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu = !_ctx.showMenu))
    }, null, 8, ["active"]),
    _createElementVNode("nav", {
      class: _normalizeClass({'active overflow-auto': _ctx.isMobile && _ctx.showMenu}),
      ref: "nav"
    }, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (title, link) => {
          return (_openBlock(), _createElementBlock("li", { key: link }, [
            (_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "nav-link",
                  to: '/' + link,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMenu = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  active: _ctx.currentPath?.includes(link),
                  variant: "text",
                  class: "nav-link mx-0",
                  small: "",
                  to: '/' + link,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showMenu = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(title), 1)
                  ]),
                  _: 2
                }, 1032, ["active", "to"]))
          ]))
        }), 128))
      ])
    ], 2)
  ]))
}