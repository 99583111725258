<template>
  <div>
    <panel class="head bg-right-md" :bg="true">
      <h1>Services</h1>
      <h2>Our clients are our drivers.</h2>
    </panel>
    <panel>
      <h4>We're here for <em>you</em>.</h4>
      <div class="columns right">
        <div class="half">
          <p>Derivitec prides itself on dedicated, one-to-one support with all our
            clients. Whether you need new functionality, new reports, or just advice on
            pricing and risk management, we&rsquo;re here for you. With offices in London and
            Hong Kong, Derivitec now provides in-hours coverage from Sydney to Los Angeles.</p>
          <strong>Our central philosophy is simple: the better our service to you, the better
            our product becomes.</strong>
        </div>
      </div>
    </panel>
    <panel class="client-relationship">
      <div class="columns">
        <div class="third">
          <h3 class="right">Your Relationship With Derivitec</h3>
        </div>
        <div class="static divider">
          <h4>Onboarding</h4>
          <p>From the first meeting, our aim is to show you what we can do for you in as
            meaningful a way as possible. We will work with you to bring representative
            positions into the system, and walk you through use of our applications and
            services in providing you with the numbers you need to see.
            No commitment, no lock in. </p>
        </div>
      </div>
      <div class="columns support">
        <div class="static divider">
          <h4>Support</h4>
          <p>We focus on building long-lasting relationships with all users of our products
            and services. No matter how complex the task, talk to us and we&rsquo;ll aim to get
            something working for you in days, not weeks. All clients have access to our UAT
            environment, allowing you to work with us quickly to ensure that what eventually
            goes through to production is exactly what you want.</p>
        </div>
      </div>
      <div class="columns right">
        <div class="static divider">
          <h4>Development</h4>
          <p>We never stop innovating. The derivatives market is in a constant state of
            change and expansion, and we expand with it. New products and functionality are
            deployed on a weekly basis, allowing all of our clients to benefit from our
            ever-increasing capabilities, together.</p>
        </div>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';

@Component({
  name: 'Services',
  components: {
    Panel,
  },
})
export default class Services extends Vue {

}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .head {
    @include coverImage('../assets/imgs/backgrounds/people-in-meeting.jpg');
  }
  .client-relationship {
    background-color: $grey--lighter;
  }
  @include small {
    .support {
      @media (max-width: $bp_medium - 1) {
        width: $magicHalfWidth;
      }
      &:after {
        content: '\00a0';
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 30px;
        position: absolute;
        width: 237px;
        height: 354px;
        right: 0;
        bottom: calc(50% - 275px);
        background-image: url('../assets/imgs/inserts/person-with-laptop.png');
      }
    }
  }
  @include medium {
    .support:after {
      width: 272px;
      height: 407px;
      background-position: top right;
      bottom: calc(50% - 200px);
    }
  }

  @include large {
    .support:after {
      width: 330px;
      height: 490px;
    }
  }
</style>
