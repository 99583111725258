<template>
  <testimonial>
    <h2>AFEX</h2>
    <q>Creating a solid strategy is the foundation of good quality risk management.
      AFEX take the time to understand their client’s business, ascertain client attitude
      to risk, identify areas where the client might be exposed, and clarify the goals the
      client wants to achieve. The establishment of a ‘single version of the truth’
      providing consistent, robust, risk reporting across all product centres, was integral
      to the provision of that service and was facilitated through the adoption of Derivitec’s
      Risk Portal. Building up from treasury, through to credit, and now into the front office,
      Derivitec has now become the core provider of all risk calculation for the firm, whose
      reports and APIs are now being married together with Microsoft’s Power BI platform to
      provide clear, insightful risk dashboards for use across the firm and the firm’s clients.</q>
    <h3>Driving The Truth</h3>
    <p>The Derivitec risk engine can be integrated into any platform, through direct API
      integration, or database driven report sharing, to provide a transparent risk view to all
      business stakeholders.</p>
    <q>Until the middle of last year Derivitec had been providing us with accurate end-of-day
      collateral and risk reports, but to derive a deeper insight into the credit quality of our
      clients, we needed to look into the trends in those numbers. As part of the daily process,
      Derivitec had provided us with risk and collateral scenario data, down to position level,
      against all of our clients, back to the beginning of 2017. We were able to convert and drop
      those reports into an SQL database within our Data Warehouse allowing us to analyse the
      Derivitec data directly against our main databases for a much more robust and holistic
      analysis of our business. This also allowed for seamless integration into our Business
      Intelligence platform, Microsoft’s Power BI. This led to an explosion in opportunities.
      At a stroke, we were able to drill down into the exposure and risk trends to portfolio level,
      department level, trade type level, right down to the individual client, allowing us to
      develop a deep insight into client trading patterns instantly. Where before the constraint
      had been on how long it took someone in the credit department to compile just one such
      client report, the constraint now was how many of them they could look at in a given day.
      Working closely with Derivitec, we were then able to automate the whole process, from position
      update to report data generation, removing key person risk in the end to end process,
      and allowing those individuals to concentrate on the far more valuable task of data analysis
      on the back of this work.</q>
    <h3>One-To-One Service</h3>
    <p>Derivitec prides itself on dedicated, one-to-one support with all our clients.
      Whether you need new functionality, new reports, or just advice on pricing and
      risk management, we’re here for you.</p>
    <q>Derivitec has always been very responsive to our needs, implementing change requests quickly
      as the popularity of the product has spread across our organisation</q>
    <name-tag name="David Sutherland" subline="Global Business Intelligence Director, AFEX" />
  </testimonial>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Testimonial from '@/components/Testimonial.vue';
import NameTag from '@/components/NameTag.vue';

@Component({
  name: 'AfexTestimonial',
  components: {
    Testimonial,
    NameTag,
  },
})
export default class AfexTestimonial extends Vue {

}
</script>
