import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3088d76b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"white-space":"pre-wrap"} }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_item = _resolveComponent("news-item")!

  return (_openBlock(), _createBlock(_component_news_item, {
    NewsTitle: _ctx.newsItem?.title
  }, {
    default: _withCtx(() => [
      (_ctx.newsItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.newsItem.subTitle)
              ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.newsItem.subTitle), 1))
              : _createCommentVNode("", true),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.newsItem.title), 1),
            (_ctx.newsItem.date)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.newsItem.author) + ", " + _toDisplayString(_ctx.humanDate), 1))
              : _createCommentVNode("", true),
            (_ctx.image)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  class: "featured-image",
                  src: _ctx.image
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "pa-5",
              innerHTML: _ctx.newsItem.body
            }, null, 8, _hoisted_6)
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_7, "The news article does not exist."))
    ]),
    _: 1
  }, 8, ["NewsTitle"]))
}