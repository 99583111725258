<template>
  <div class="toggle" :class="{'active': active}" @click="realClick" tabindex="0"
       @keypress.enter="passClick" @keypress.prevent.space="passClick">
    <div class="burger"></div>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,  Emit,
} from 'vue-facing-decorator';

@Component({
  name: 'MenuToggle',
})
export default class MenuToggle extends Vue {
  @Prop()
  active: boolean

  realClick() {
    this.$el.blur();
    this.passClick();
  }

  @Emit("click")
  passClick() {
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/utilities';

  .toggle {
    position: sticky;
    right: $horizontalMargin;
    @include medium {
      right: $horizontalMargin--medium;
    }
    height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.5s ease;
    z-index: 10;

    .burger, &:before, &:after {
      display: block;
      background: $blue;
      width: 30px;
      height: 3px;
      padding: 0;
      transition: transform 0.5s ease, background-color 0.5s ease;
    }

    &:before, &:after {
      content: '';
    }

    &:focus {
      outline: none;
      transform: scale(1.2);
    }

    &.active {
      .burger {
        background-color: transparent;
      }

      &:before {
        transform: rotate(45deg) translate(6px, 6px);
      }

      &:after {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }

    @include large {
      /* We need to wrap these styles like this as some browsers (Safari) behave poorly when
        switching between mobile and desktop menu types */
      &:not(.active) {
        display: none;
      }
    }
  }
</style>
