<template>
  <footer>
    <div class="get-in-touch" v-if="$route.name !== 'Contact'">
      <p>See what Derivitec can do for you and your organisation, and manage your
        risks the way they <em>should</em> be managed.</p>

      <router-link class="button light" :to="{ name:'Contact', params: { scrollToContent: true } }">
        Get In Touch
      </router-link>
    </div>
    <div class="site-footer">
      <div class="row">
        <logo></logo>
        <div>
          <h4>Contact</h4>
          <p><a href="tel:+442036683682">+44 (203) 668 3682</a></p>
          <p><a href="mailto:info@derivitec.com">info@derivitec.com</a></p>
        </div>

        <div class="socials">
          <a href="https://www.youtube.com/channel/UCg4vxKgpnO2yy53ADg8F_Kg/feed"><icon :icon="['fab', 'youtube-square']"></icon></a>
          <a href="https://www.linkedin.com/company/derivitec-ltd?trk=prof-exp-company-name"><icon :icon="['fab', 'linkedin']"></icon></a>
        </div>
      </div>
      <div class="row">
        <div>
          <p><router-link :to="{ name: 'CookieSettings' }">Cookies</router-link> |
            Copyright &copy; All Rights Reserved</p>
        </div>
        <div>
          <p>Design: <a href="http://elless.co.uk">Elless</a></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from './Logo.vue';
import Icon from './Icon.vue';
import {Component, Vue} from "vue-facing-decorator";

@Component({
  name: 'FooterComp',
  components: { Logo, Icon },
})
export default class FooterComp extends Vue {
}

</script>

<style lang="scss" scoped>
  @import "../styles/utilities";

  .get-in-touch {
    background: linear-gradient(to right, $blue, $blue--dark);
    text-align: center;
    padding: 50px 0 105px;
    @include small {
      padding-bottom: 50px;
    }
    p {
      font-size: 24px;
      line-height: 1.4em;
      color: white;
      font-family: $fontPlayfair;
      margin: 0 auto;
      width: percentage(260 / $bp_xs--num);
      @include medium {
        width: 870px;
      }
      em {
        font-style: italic;
      }
    }
    .button {
      font-size: 14px;
    }
  }

  .site-footer {
    background-color: $black;
    padding: 50px 30px 45px;
    @include small {
      padding: 50px 40px 40px;
    }
    h4 {
      color: $blue;
      font-family: $fontSource;
      line-height: 1.25em;
      margin-bottom: 0;
    }
    h4, p {
      font-size: $fontsize--smaller;
      text-transform: uppercase;
    }
    p, a {
      color: $grey;
      line-height: 2em;
      text-decoration: none;
      font-weight: 300;
      letter-spacing: 0.05em;
      margin: 0;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .row > * {
    margin-top: 1.8rem;
    @include small {
      margin-top: 0;
    }
  }

  @include small {
    .row {
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-bottom: 110px;
      }
    }
  }

  .socials {
    @include small {
      width: 30%;
      text-align: right;
    }
    @include medium {
      width: 50%;
    }
    a {
      line-height: 1.2em;
      font-size: $fontsize--medium;
      margin: 0 5px;
      transition: color 0.5s ease;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: white;
      }
    }
  }
</style>
