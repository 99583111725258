<template>
  <div class="form-group">
    <textarea :id="fieldName" v-model="value" required />
    <label :for="fieldName"  class="control-label">{{placeholder}}</label>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,  Model
} from 'vue-facing-decorator';

@Component({
  name: 'TextAreaField',
})
export default class TextAreaField extends Vue {
  @Prop()
  placeholder: string

  @Model()
  value: string

  @Prop()
  name: string

  get fieldName() {
    return this.name || btoa(this.placeholder + parseInt(`${Math.random() * 100}`, 10));
  }

}
</script>

<style lang="scss" scoped>
  @import "../styles/utilities";
  @include input-base;

  .form-group {
    textarea {
      height: 2.6rem;
      min-height: 0;
      resize: vertical;
      display: block;
      background: $grey--lighter;
      padding: 0 0 0 0.5rem;
      border: 0.125rem solid transparent;
      line-height: 1.9;
      &:focus {
        border-color: $grey--lighter;
        background: white;
      }
      &:focus, &:valid {
        min-height: 100px;
      }
    }
  }
</style>
