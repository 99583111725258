<template>
  <profile>
    <template #headshot>
      <img src="../../assets/imgs/headshots/david-shannon.jpg" alt="Photo of David Shannon"/>
    </template>
    <div>
      <h2>David Shannon</h2>
      <h3>Partner | Assembly Capital Partners</h3>
      <p>David is a Partner at Assembly Capital Partners which is a specialist FinTech venture
        capital investor. David has over twenty five years experience of trading, risk management
        and private investment. He has run investment bank trading businesses across a range of
        asset classes on both a regional and global basis, including ten years based in Tokyo, Hong
        Kong and Singapore. He set up the Asian office of a large established hedge fund. Most
        recently in banking he was Head of Market Risk at a leading investment bank. David holds an
        MEng in Manufacturing Science and Engineering.</p>
    </div>
  </profile>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Profile from '@/components/Profile.vue';

@Component({
  name: 'DavidShannonProfile',
  components: {
    Profile,
  },
})
export default class DavidShannonProfile extends Vue {

}
</script>
