<template>
  <div class="error-page">
    <panel>
      <h1>Something went wrong</h1>
      <h2>Error 404</h2>
      <p>The page you requested could not be found. If you are expecting to see something here
        please context <a href="mailto:support@derivitec.com">support@derivitec.com</a>.</p>
      <p><router-link to="/" class="button">Return to homepage</router-link></p>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';

@Component({
  name: 'Error',
  components: {
    Panel,
  },
})
export default class ErrorPage extends Vue {

}
</script>
<style lang="scss" scoped>
  @import '../styles/utilities';

  .error-page {
    background: $black;
    padding-top: 10vh;
  }

  .error-page > :first-child {
    background: $white;
    height: calc(100vh - 35vh);
    &:after {
      content: none;
    }
  }
</style>
