<template>
  <div>
    <panel class="head bg-right-sm" :bg="true">
      <h1>Hedge Funds</h1>
      <h2>Know your risks.<br />All of them.</h2>
    </panel>
    <panel>
      <div class="columns">
        <h4 class="half">A full end to end risk platform, front to back.</h4>
      </div>
      <div class="columns right">
        <p class="half">The Derivitec Risk Portal allow you to upload your
          portfolio directly, regardless of asset class, regardless of complexity.
          You can then run any risk that makes sense to your organisation with all
          supporting market data provided on an end of day basis as standard. Integrate
          your live data and workflow feeds through our powerful C sharp API technology
          and you have a full end to end risk platform, front to back. Use our Python
          APIs to generate reports and analysis, pre and post trade, the scope is limitless.</p>
      </div>
    </panel>
    <panel class="features">
      <div class="columns">
        <div class="third">
          <h3 class="right">Features & Benefits</h3>
        </div>
        <div class="static divider">
          <h4>Consistency</h4>
          <p>We operate a single version of the truth. Your entire organisation, be it back office,
            middle office or front office, use the same data and the same models, ensuring that
            everyone is on the same page, all the time.</p>
        </div>
      </div>
      <div class="columns flexibility">
        <div class="static divider">
          <h4>Flexibility</h4>
          <p>There is no one right answer when it comes to risk. You need to have confidence that
            you&rsquo;ve looked at all the angles, because the results are frequently surprising. In
            addition to the comprehensive standard risk reports, we tailor make reports to fit
            your organisations specifications exactly, and even allow you to produce analysis on
            the fly through our powerful Python API.</p>
        </div>
      </div>
      <div class="columns right power">
        <div class="static divider">
          <h4>Power</h4>
          <p>Regardless of whether you&rsquo;re trading 20 stocks, or 10,000 derivatives, our award
            winning cloud based technology scales on demand to meet your calculation requirements.
            Gone are the days of the overnight, non-completing risk run. Plug this into your other
            workflow components through our C-sharp API and you have a front to back system of
            unlimited power.</p>
        </div>
      </div>
    </panel>
    <panel class="columns">
      <div class="third">
        <client-box class="company-logo" :standalone="true">
          <h5 class="name">Multi-strategy Hedge&nbsp;Fund</h5>
          <p class="subline">Hong Kong</p>
        </client-box>
      </div>
      <div class="two-thirds">
        <blockquote>
          <p>Derivitec’s robust IT infrastructure and user-friendly Risk Portal are
            unparalleled.</p>
          <p>Derivitec’s robust IT infrastructure and user-friendly Risk Portal are unparalleled
            inasmuch as they afford us a complete suite of analytical and reporting tools that
            allow for in-depth analysis and fine toothcomb scrutiny of portfolio structures at
            fund and entity levels.</p>
          <footer>
            <cite>Multi-strategy Hedge Fund</cite>
            <p>Hong Kong</p>
          </footer>
        </blockquote>
        <router-link class="button" to="/clients">Read More</router-link>
      </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import ClientBox from '@/components/ClientBox.vue';

@Component({
  name: 'HedgeFunds',
  components: {
    Panel,
    ClientBox,
  },
})
export default class HedgeFunds extends Vue {

  mounted(){
    document.dispatchEvent(new Event('render-event'));
  }
}
</script>
<style lang="scss" scoped>
  @import '../styles/utilities';

  .head {
    @include coverImage('../assets/imgs/backgrounds/person-by-window.jpg');
  }
  .features {
    background-color: $grey--lighter;
  }

  .flexibility, .power {
    &:after {
      content: '\00a0';
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 350px;
      height: 520px;
    }
  }
  .flexibility {
    margin-bottom: 520px;
    &:after {
      right: 0;
      @include retinaImage('../assets/imgs/inserts/desktop-displaying-market-data.png', 350);
    }
  }
  .power:after {
    display: none;
  }

  @include small {
    .flexibility {
      // Adhere to original sizing outside of small and large bps
      @media (max-width: $bp_large - 1) {
        width: $magicHalfWidth;
      }
      margin-bottom: 0;
      &:after {
        width: 35%;
        bottom: calc(50% - 275px);
      }
    }
  }

  @include medium {
    .flexibility:after {
      width: 350px;
    }
    .power:after {
      display: block;
      left: 0;
      bottom: 0;
      @include retinaImage('../assets/imgs/inserts/people-walking-triangle.png', 350);
    }
  }
</style>
