<template>
  <div>
    <panel class="head" :bg="true">
      <h1>Company</h1>
      <h2>Building a single version of the truth.</h2>
    </panel>
    <panel>
      <p>Founded in Dec 2011, the company has been working intensively towards cloud-based solutions
        for risk and portfolio management. Our products have been designed to allow users to start
        analysing risk on their derivatives portfolios in a matter of minutes. With industrial
        standard models and sanitised market data as standard, customers can focus on the business
        of business, while we concentrate on the business of risk.</p>
        <div v-for="(team, teamName) in company" :key="team">
          <h3>{{teamName}}</h3>
          <ul class="grid align">
            <li v-for="(employee) in team" :key="employee.name">
              <router-link :to="{ name: routeName(employee.name)}" >
                <headshot :name="employee.name" :job="employee.role">
                  <img :src="thumb(employee.name)" :alt="altText(employee.name)" />
                </headshot>
              </router-link>
            </li>
          </ul>
        </div>
    </panel>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue,
} from 'vue-facing-decorator';
import Panel from '@/components/Panel.vue';
import Headshot from '@/components/Headshot.vue';
import {company} from "@/router/index"
import {groupBy} from 'lodash'
@Component({
  name: 'Company',
  components: {
    Panel,
    Headshot,
  },
})
export default class Company extends Vue {

  routeName(name: string) {
    return name.replace(' ', '');
  }

  get company(){return groupBy(company, "team")}

  altText(name: string) {
    return `Photo of ${name}`;
  }

  thumb(name: string) {
    return require(`../assets/imgs/headshots/${name.toLowerCase().replace(' ', '-')}--small.jpg`);
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/utilities';

  .head {
    @include coverImage('../assets/imgs/backgrounds/curved-glass-building-from-ground-level.jpg');
  }

  a {
    text-decoration: none;
    outline: none;
  }

  h3 {
    margin: $verticalMargin--larger 0 $verticalMargin--smaller;
  }
</style>
