import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e37b6232"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name-tag" }
const _hoisted_2 = { class: "name" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1),
    (_ctx.subline)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subline), 1))
      : _createCommentVNode("", true)
  ]))
}