import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56ee4278"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "third" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "two-thirds" }
const _hoisted_4 = { class: "headshot" }
const _hoisted_5 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_panel = _resolveComponent("panel")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_panel, {
      class: "head",
      bg: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.Name), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.breadcrumb), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_panel, {
      class: "columns py-20",
      id: "pageContent"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h3", null, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/company'
            }, {
              default: _withCtx(() => [
                _createTextVNode("Company")
              ]),
              _: 1
            }),
            _createTextVNode(" / " + _toDisplayString(_ctx.breadcrumb), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "headshot", {}, undefined, true)
            ])
          ])
        ])
      ]),
      _: 3
    })
  ]))
}