<template>
  <header class="pb-3 pt-4 elevation-3">
    <logo></logo>
    <menu-toggle :active="isMobile && showMenu" @click="showMenu = !showMenu"></menu-toggle>
    <nav :class="{'active overflow-auto': isMobile && showMenu}" ref="nav" >
     <ul>
      <li v-for="(title, link) in links" :key="link">
        <router-link
          v-if="isMobile"
           class="nav-link"
           :to="'/' + link"
           @click.native="showMenu = false">
          {{title}}
        </router-link>
        <v-btn
          v-else
          :active="currentPath?.includes(link)"
          variant="text"
          class="nav-link mx-0"
          small
          :to="'/' + link"
          @click.native="showMenu = false">
          {{title}}
        </v-btn>
      </li>
     </ul>
    </nav>
  </header>
</template>

<script lang="ts">
import {
  Component, Vue, Watch,  Ref,
} from 'vue-facing-decorator';
import Logo from './Logo.vue';
import MenuToggle from './MenuToggle.vue';
import {useDisplay} from "vuetify";
import {useRoute} from "vue-router";

@Component({
  name: 'Header',
  components: { Logo, MenuToggle },
})
export default class Header extends Vue {

  route: any | null = null

  get currentPath(){return this.route?.path}

  mounted(){
    this.route = useRoute()
  }

  @Ref('nav')
  navRef: HTMLElement

  links: any = {
    'hedge-funds': 'Hedge Funds',
    'remittance-funds': 'Remittance Funds',
    platforms: 'Platforms',
    product: 'Product',
    services: 'Services',
    clients: 'Clients',
    // partners: 'Partners',
    company: 'Company',
    awards: 'Awards',
    news: 'News',
    contact: 'Contact',
  }

  active: boolean = false

  scrollable: boolean = false

  showMenu: boolean = false

  get isMobile() {return useDisplay().mobile.value}

  @Watch('active')
  static onActive(newVal, oldVal) {
    if (newVal === oldVal) return;
    if (newVal === true) {
      document.body.style.overflow = 'hidden';
    } else if (newVal === false) {
      document.body.style.overflow = 'visible';
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '../styles/utilities';

  $marginTop: 0vh;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: $marginTop;
    width: 100%;
    z-index: 2;
    padding: 0 $horizontalMargin;
    @include medium {
      padding: 0 $horizontalMargin--medium;
    }
    background-color: $black;
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    background-color: $black;
    transition: opacity 0.5s ease, visibility 0.5s step-end;

    &.scrollable {
      align-items: flex-start;
    }

    &.active {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s ease;
      overflow-y: scroll;
    }

    li {
      display: block;
      /* See large breakpoint styling below */
    }

    a {
      font-family: $fontPlayfair;
      font-size: $fontsize--resphuge * 1.5;
      line-height: $fontsize--resphuge * 2;
      text-decoration: none;
      color: $grey;
      transition: color 0.5s ease, border-color 0.5s ease;

      @include small {
        font-size: $fontsize--resphuge;
        line-height: $fontsize--resphuge * 1.5;
      }

      /* See large breakpoint styling below */

      &.router-link-active {
        color: darken($white, 20%);
      }

      &:hover, &:focus, &:active {
        color: $white;
      }
    }

    @include large {
      /* We need to wrap these styles like this as some browsers (Safari) behave poorly when
        switching between mobile and desktop menu types */
      &:not(.active) {
        display: block;
        position: relative;
        top: 0;
        width: auto;
        height: auto;
        background-color: transparent;
        visibility: visible;
        opacity: 1;
        overflow: hidden;
        li {
          display: inline;
        }
        a {
          @include small-title;
          color: $grey;
          line-height: 1;
          margin-left: 28px;
          letter-spacing: 0.15em;
          &.active {
            overflow-y: hidden;
          }
          &:hover {
            border-color: $white;
          }
        }
      }
    }
  }
</style>
